<template>
	<transition name="slideup">
		<div v-if="panelopen" class="panel">
			<div v-if="this.$store.state.config.debug == 1" class="debug">Panel | {{ panelname }}</div>

			<div class="row panel_header">
				<div class="col col-6 middle">
					<h1>{{ translate.LOSTPASSWORD_KOP }}</h1>
				</div>
				<div class="col-6 middle-right">
					<a v-on:click="setactiveproperty('toppanel', '')" class="bi bi-x tekstblauw floatright"></a>
				</div>
			</div>

			<div class="panel_content horizontalverticalcenter">
				<div>
					<h2>{{ translate.LOSTPASSWORD_TEKST }}</h2>
					<br />

					<form @submit="postdata">
						<div class="row">
							<div class="col col-12">
								<input type="text" class="text" v-model="email" :placeholder="[[translate.CREATEACCOUNT_EMAIL]]" />
							</div>
						</div>

						<div v-if="message" class="row">
							<div class="col col-12">
								<div class="message">{{ message }}</div>
							</div>
						</div>

						<div class="row">
							<div class="col col-12">
								<div v-if="postok">
									<button class="button" type="submit">{{ translate.LOSTPASSWORD_BUTTON }}</button>
								</div>
								<div v-else>
									<a class="button grijs">{{ translate.LOSTPASSWORD_BUTTON }}</a>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import axios from 'axios';

export default {
	name: 'Login',
	data() {
		return {
			panelname: 'LostPassword',
			email: '',
			message: '',
		};
	},
	computed: {
		translate() {
			return this.$store.state.translate;
		},
		panelopen() {
			if (this.$store.state.active.toppanel == this.panelname) {
				return true;
			} else {
				return false;
			}
		},
		loginurl() {
			return 'https://www.hockeydo.com/auth/login.php?return=' + encodeURIComponent(window.location);
		},

		postok() {
			if (this.email.trim() && !this.message.trim()) {
				return true;
			} else {
				return false;
			}
		},
	},
	methods: {
		postdata(e) {
			e.preventDefault();

			this.message = '';

			if (this.$store.state.config.debug == 1) {
				console.log('lostpassword');
			}

			var post = new Object();
			post.task = 'lostpassword';
			post.email = this.email;
			post.subject = this.$store.state.translate.LOSTPASSWORD_EMAIL_SUBJECT;
			post.body = this.$store.state.translate.LOSTPASSWORD_EMAIL_BODY;
			post.footer = this.$store.state.translate.LOSTPASSWORD_EMAIL_FOOTER;
			post.linktext = this.$store.state.translate.LOSTPASSWORD_EMAIL_LINKTEXT;
			post.language = this.$store.state.active.languagewebsite;

			// console.log(post);

			axios.post(this.$store.state.config.apiserver + 'users/panels/Lostpassword.php', post)
				.then(() => {
					this.message = this.$store.state.translate.LOSTPASSWORD_SUCCES;
				})
				.catch((error) => {
					this.errorMessage = error.message;
					console.error('There was an error!', error);
				});
		},
	},
	watch: {
		panelopen(newValue) {
			if (newValue === true) {
				this.email = '';
				this.message = '';
			}
		},
	},
};
</script>

<style scoped>
input {
	width: 400px;
}

.button,
button {
	width: 400px;
	margin-bottom: 15px;
}

.message {
	padding: 0px 15px 15px 15px;
}
</style>
