<template>
	<PostsScopeFilter />

	<PostsFilter />

	<Posts />
</template>

<script>
import Posts from '@/components/posts/Posts.vue';
import PostsScopeFilter from '@/components/posts/PostsScopeFilter.vue';
import PostsFilter from '@/components/posts/PostsFilter.vue';

export default {
	name: 'View',
	data() {
		return {};
	},
	components: {
		Posts,
		PostsScopeFilter,
		PostsFilter,
	},
	computed: {},
	watch: {},

	methods: {},
};
</script>

<style scoped></style>
