<template>
	<div class="menuleft">
		<router-link :to="{name: 'Home'}">
			<div v-if="section == 'home'">
				<a class="mainmenu_item">
					<i class="bi bi-house-fill"></i>
					<div class=" ">Home</div>
				</a>
			</div>

			<div v-else>
				<a class="mainmenu_item">
					<i class="bi bi-house"></i>
					<div class=" ">Home</div>
				</a>
			</div>
		</router-link>

		<router-link v-if="competitions" :to="{name: 'Games', params: {competition: latestCompetitionSlug, game: 'game-1', video: 'video-1', mode: 'game'}}">
			<div v-if="section == 'games'">
				<a class="mainmenu_item">
					<GameIconActive />
					<div class=" ">{{ translate.MENU_PLAY }}</div>
				</a>
			</div>

			<div v-else>
				<a class="mainmenu_item">
					<GameIcon />
					<div class=" ">{{ translate.MENU_PLAY }}</div>
				</a>
			</div>
		</router-link>

		<router-link :to="{name: 'Posts', params: {continent: currentContinentSlug, country: currentCountrySlug, region: currentRegionSlug, club: currentClubSlug, scope: currentScope, sub: 'posts', subsub: 'all'}}">
			<div v-if="this.$store.state.active.section == 'posts'">
				<a class="mainmenu_item">
					<i class="bi bi-chat-square-text-fill"></i>
					<div class=" ">{{ translate.MENU_POSTS }}</div>
				</a>
			</div>

			<div v-else>
				<a class="mainmenu_item">
					<i class="bi bi-chat-square-text"></i>
					<div class=" ">{{ translate.MENU_POSTS }}</div>
				</a>
			</div>
		</router-link>

		<router-link :to="{name: 'Clubs', params: {continent: currentContinentSlug, country: currentCountrySlug, region: currentRegionSlug, club: currentClubSlug, scope: 'clubinfo', sub: 'info', subsub: 'info'}}">
			<div v-if="this.store.state.current.club">
				<div v-if="section == 'clubs'">
					<a class="mainmenu_item">
						<i class="bi bi-shield-fill"></i>
						<div class=" ">{{ translate.MENU_CLUB }}</div>
					</a>
				</div>

				<div v-else>
					<a class="mainmenu_item">
						<i class="bi bi-shield"></i>
						<div class=" ">{{ translate.MENU_CLUB }}</div>
					</a>
				</div>
			</div>
		</router-link>

		<router-link :to="{name: 'We', params: {group: 'referees', view: 'info'}}">
			<div v-if="section == 'we'">
				<a class="mainmenu_item">
					<i class="bi bi-people-fill"></i>
					<div class=" ">{{ translate.MENU_WE }}</div>
				</a>
			</div>

			<div v-else>
				<a class="mainmenu_item">
					<i class="bi bi-people"></i>
					<div class=" ">{{ translate.MENU_WE }}</div>
				</a>
			</div>
		</router-link>
	</div>
</template>

<script>
import {mapState} from "vuex";

import GameIcon from "@/components/common/icons/GameIcon.vue";
import GameIconActive from "@/components/common/icons/GameIconActive.vue";

export default {
	name: "Footer",

	data() {
		return {};
	},
	components: {
		GameIcon,
		GameIconActive,
	},
	methods: {},

	computed: {
		...mapState(["active", "competitions"]),

		section() {
			if (this.$store.state.active.section) {
				return this.$store.state.active.section;
			} else {
				return null;
			}
		},

		currentClubSlug() {
			if (this.$store.state.current.club) {
				return this.$store.state.current.club.slug;
			} else {
				if (this.$store.state.user && this.$store.state.user.club) {
					return this.$store.state.user.club.slug;
				} else {
					return "-";
				}
			}
		},
		currentRegionSlug() {
			if (this.$store.state.current.region) {
				return this.$store.state.current.region.slug;
			} else {
				if (this.$store.state.user && this.$store.state.user.club) {
					return this.$store.state.user.club.region_slug;
				} else {
					return "-";
				}
			}
		},
		currentCountrySlug() {
			if (this.$store.state.current.country) {
				return this.$store.state.current.country.slug;
			} else {
				if (this.$store.state.user && this.$store.state.user.club) {
					return this.$store.state.user.club.country_slug;
				} else {
					return "-";
				}
			}
		},
		currentContinentSlug() {
			if (this.$store.state.current.continent) {
				return this.$store.state.current.continent.slug;
			} else {
				if (this.$store.state.user && this.$store.state.user.club) {
					return this.$store.state.user.club.continent_slug;
				} else {
					return "-";
				}
			}
		},

		currentScope() {
			var scope = "world";

			if (this.$store.state.current.continent) {
				scope = "continent";
			}

			if (this.$store.state.current.country) {
				scope = "country";
			}

			if (this.$store.state.current.region) {
				scope = "region";
			}

			if (this.$store.state.current.club) {
				scope = "club";
			}

			return scope;
		},
		latestCompetitionSlug() {
			var competitions = this.competitions;
			
			if (this.competitions){
				
				const highestOrderingCompetition = this.competitions.reduce((max, competition) => (competition.ordering > max.ordering ? competition : max), competitions[0]);
	
				return highestOrderingCompetition.slug;
			} else {
				
				return null;
			}

			
		},
		latestGameNumber() {
			// 			var competitions = this.competitions;
			//
			// 			const highestOrderingCompetition = this.competitions.reduce((max, competition) => (competition.ordering > max.ordering ? competition : max), competitions[0]);
			//
			return "1";
			//
		},
	},
};
</script>

<style scoped>
.menuleft {
	margin: 80px 15px 15px 15px;
	left: 15px;
}

.mainmenu_item {
	width: 100%;
	overflow: hidden;
	padding: 5px;
	transition: all 0.2s;
	border-radius: 6px;
}

.mainmenu_item:hover {
	background: rgba(0, 0, 0, 0.05);
}

.mainmenu_item i {
	float: left;
	display: block;
	padding: 0px 5px;
	color: var(--groen);
	padding-top: 8px;
	margin-right: 5px;
}

.mainmenu_item div {
	float: left;
	padding: 10px 5px;
	display: block;
}

.icon.game {
	position: relative;
	top: 5px;
	margin-right: 10px;
}
</style>
