<template>
	<transition name="slideup">
		<div v-if="this.$store.state.active.toppanel == panelname" class="panel">
			<div v-if="this.$store.state.config.debug == 1" class="debug">Toppanel | {{ panelname }}</div>

			<div class="row panel_header">
				<div class="col col-6 middle">
					<h1>{{ translate.POSTFILTER_KOP }}</h1>
				</div>
				<div class="col-6 middle-right">
					<a v-on:click="setactiveproperty('toppanel', '')" class="bi bi-x tekstblauw floatright"></a>
				</div>
			</div>

			<div class="panel_content">
				<div class="row">
					<div class="col-12">
						<a v-on:click="setfiltercat(null)" class="button ghost mb15">{{ translate.POSTCATFILTER_ALLES }}</a>

						<div v-for="postcat in this.$store.state.staticdata.postcats" :key="postcat.id">
							<div v-if="this.$store.state.active.filtercat">
								<div v-if="this.$store.state.active.filtercat.id == postcat.id">
									<a v-on:click="setfiltercat(postcat)" class="button mb15">{{ postcat[this.$store.state.active.languagewebsite] }}</a>
								</div>

								<div v-else>
									<a v-on:click="setfiltercat(postcat)" class="button ghost mb15">{{ postcat[this.$store.state.active.languagewebsite] }}</a>
								</div>
							</div>

							<div v-else>
								<a v-on:click="setfiltercat(postcat)" class="button ghost mb15">{{ postcat[this.$store.state.active.languagewebsite] }}</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
export default {
	data() {
		return {
			panelname: 'PostsFilterCats',
		};
	},

	methods: {
		setfiltercat(cat) {
			this.setactiveproperty('filtercat', cat);
			this.setactiveproperty('toppanel', '');
		},
	},

	computed: {
		filtercatid() {
			return this.$store.state.active.filtercatid;
		},
	},
};
</script>
