<template>
	<transition name="slideup">
		<div v-if="this.store.state.active.toppanel == panelname" class="panel">
			<div v-if="this.store.state.config.debug == 1" class="debug">Toppanel | {{ panelname }}</div>

			<div class="row panel_header middle">
				<div class="col col-6 middle">
					<h1>{{ translate.POSTSCREATE_KIESTAAL }}</h1>
				</div>
				<div class="col-6 middle-right">
					<a v-on:click="setactiveproperty('toppanel', '')" class="bi bi-x tekstblauw floatright"></a>
				</div>
			</div>

			<div class="panel_content">
				<div v-for="language in languages" :key="language.id">
					<a class="button ghost mb10" v-if="language.id != 1" v-on:click="setlanguage(language)">{{ language.name }}</a>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
export default {
	data() {
		return {
			panelname: 'PostCreateLanguage',
		};
	},

	components: {},
	methods: {
		setlanguage(language) {
			this.setactiveproperty('postlanguage', language);
			this.setactiveproperty('toppanel', '');
		},
	},

	computed: {
		languages() {
			return this.$store.state.staticdata.languages;
		},
	},

	watch: {},
};
</script>

<style scoped></style>
