<template>
	<div v-if="searchstring" id="searchresults">
		<div v-if="searchresults.length > 0">
			<div v-for="club in searchresults" :key="club">
				<div v-on:click="gotoclub(club)" class="club">
					<span class="dik">{{ club.name_long }}</span>
					<span class="tekstgrijs">&bull; {{ club.place }} &bull; {{ club[this.$store.state.active.languagewebsite] }}</span>
				</div>
			</div>
		</div>
		<br />

		<div v-if="searchresults.length < 4">
			<div v-if="loggedin">
				<a class="button" v-on:click="setactiveproperty('panel', 'AddClub')">{{ translate.SEARCH_ADDCLUB }}</a>
			</div>

			<div v-if="!loggedin">
				<a class="button" v-on:click="setactiveproperty('panel', 'Login')">{{ translate.SEARCH_ADDCLUB }}</a>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios';

export default {
	data() {
		return {
			searchresults: [],
		};
	},
	methods: {
		gotoclub(club) {
			if (this.userhasnoclub) {
				this.setactiveproperty('setuserclub', club);
				this.setactiveproperty('toppanel', 'SetUserClubConfirmation');
				this.setactiveproperty('searchstring', '');
			} else {
				if (this.$store.state.config.debug == 1) {
					console.log('getclub');
				}

				this.setactiveproperty('screen', null);

				var post = new Object();
				post.task = 'getclub';
				post.clubid = club.id;

				axios.post(this.$store.state.config.apiserver + 'map/Search.php', post)
					.then((response) => {
						var club = response.data;

						this.searchresults = [];

						this.setactiveproperty('searchstring', '');

						this.$router.push({name: 'Clubs', params: {continent: club.continentslug, country: club.countryslug, region: club.regionslug, club: club.slug, scope: 'clubinfo', sub: 'info', subsub: 'info'}});

						// http://localhost:8080/posts/europe/the-netherlands/midden-nederland/shinty/club/posts/all
					})
					.catch((error) => {
						this.errorMessage = error.message;
						console.error('There was an error!', error);
					});
			}
		},
	},

	computed: {
		searchstring() {
			return this.$store.state.active.searchstring;
		},

		userhasnoclub() {
			if (this.$store.state.user) {
				if (this.$store.state.user.id > 0) {
					if (this.$store.state.user.clubid < 1) {
						return true;
					}
				} else {
					return false;
				}
			} else {
				return false;
			}

			return false;
		},
	},
	watch: {
		searchstring() {
			if (this.$store.state.active.searchstring.length > 0) {
				if (this.$store.state.config.debug == 1) {
					console.log('search');
				}

				var post = new Object();
				post.task = 'searchclubs';
				post.searchstring = this.searchstring;

				// console.log(this.searchstring);

				axios.post(this.$store.state.config.apiserver + 'map/Search.php', post)
					.then((response) => {
						// console.log(response.data);

						this.searchresults = response.data;
					})
					.catch((error) => {
						this.errorMessage = error.message;
						console.error('There was an error!', error);
					});
			} else {
				this.searchresults = [];
			}
		},
	},
};
</script>
<style scoped>
#searchresults {
	position: fixed;
	top: 125px;
	left: 10px;
	right: 15px;
	z-index: 10;
	height: auto;
	background: #fff;
	padding: 15px;
	overflow: hidden;
	border-radius: 5px;
}

#searchresults .club {
	padding: 10px 0px;
	transition: all 0.4s;
	border-bottom: 1px solid #eee;
	cursor: pointer;
}
#searchresults .club:hover {
	background: #f8f8f8;
}

/* SM */
@media (min-width: 576px) {
}

/* MD */
@media (min-width: 768px) {
	#searchresults {
		position: fixed;
		top: 125px;
		left: 50%;
		transform: translate(-50%);
		right: 90px;
		width: 400px;
		z-index: 10;
		height: auto;
		background: #fff;
		padding: 15px;
		overflow: hidden;
		border-radius: 5px;
	}
}

/* LG */
@media (min-width: 992px) {
	#searchcontainer {
		position: fixed;
		left: 0px;
		right: 0px;
		top: 80px;
		z-index: 10;
	}

	#searchcontainer .search {
		margin: 0 auto;
		width: calc(100vw - 600px);
		max-width: 800px;
	}
}

/* XL */
@media (min-width: 1200px) {
}
</style>
