<template>
	<div v-if="this.$store.state.active.toppanel == panelname" class="panel">
		<div v-if="this.$store.state.config.debug == 1" class="debug">Toppanel | {{ panelname }}</div>

		<div class="row panel_header middle">
			<div class="col-6">
				<h1>{{ translate.SETOWNCLUB_DITISMIJNCLUB }}</h1>
			</div>
			<div class="col-6">
				<a v-on:click="setactiveproperty('toppanel', '')" class="bi bi-x tekstblauw floatright"></a>
			</div>
		</div>

		<div class="panel_content horizontalverticalcenter">
			<div class="centertext">
				<h1 class="centertext">{{ club.club_namelong }} {{ club.name_long }}</h1>
				<h2 class="centertext">{{ club['country_' + this.$store.state.active.languagewebsite] }}{{ club[this.$store.state.active.languagewebsite] }}</h2>
				<div class="centertext p15">{{ translate.SETOWNCLUB_TEKST }}</div>

				<a class="button" v-on:click="setownclub()">{{ translate.SETOWNCLUB_BUTTON }}</a>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios';

export default {
	data() {
		return {
			response: null,
			panelname: 'SetUserClubConfirmation',
			taskShowPanel: false,
		};
	},
	computed: {
		club() {
			return this.$store.state.active.setuserclub;
		},
	},
	watch: {},

	methods: {
		setownclub() {
			if (this.$store.state.config.debug == 1) {
				console.log('setownclub');
			}

			var clubid = 0;

			if (this.club?.id) {
				clubid = this.club?.id;
			}

			if (this.club?.club_id) {
				clubid = this.club?.club_id;
			}

			const post = new Object();
			post.task = 'setuserclub';
			post.clubid = clubid;
			post.hdaccesstoken = this.$store.state.user.hdaccesstoken;

			axios.post(this.$store.state.config.apiserver + 'users/panels/SetUserClubConfirmation.php', post)
				.then((response) => {
					console.log(response.data);
					this.$store.dispatch('fetchuser');

					this.setactiveproperty('toppanel', '');
					this.setactiveproperty('panel', '');
					this.setactiveproperty('setuserclub', '');
				})
				.catch((error) => {
					this.errorMessage = error.message;
					console.error('There was an error!', error);
				});
		},
	},
};
</script>
