import axios from 'axios';

import store from '../store';

export default {
	fetchclubs({commit}) {
		if (store.state.config.debug == 1) {
			console.log('fetch clubs');
		}

		var post = new Object();
		post.task = 'fetchclubs';
		axios.post(store.state.config.apiserver + 'app-actions.php', post)
			.then((response) => {
				commit('setClubs', response.data);

				commit('addLoadingItem', 'clubs');
			})
			.catch((error) => {
				this.errorMessage = error.message;
				console.error('There was an error!', error);
			});
	},

	fetchContinentsCountriesRegions({commit}) {
		if (store.state.config.debug == 1) {
			console.log('fetch ContinentsCountriesRegions');
		}

		var post = new Object();
		post.task = 'ContinentsCountriesRegions';

		axios.post(store.state.config.apiserver + 'app-actions.php', post)
			.then((response) => {
				// console.log(response.data);

				const payload = new Object();
				payload.property = 'ContinentsCountriesRegions';
				payload.value = response.data;

				commit('addLoadingItem', 'continentscountriesregions');

				commit('setstaticproperty', payload);
			})
			.catch((error) => {
				this.errorMessage = error.message;
				console.error('There was an error!', error);
			});
	},

	fetchlanguages({commit}) {
		if (store.state.config.debug == 1) {
			console.log('fetch languages');
		}

		var post = new Object();
		post.task = 'getlanguages';
		axios.post(store.state.config.apiserver + 'app-actions.php', post)
			.then((response) => {
				//console.log(response.data);

				const payload = new Object();
				payload.property = 'languages';
				payload.value = response.data;

				commit('setstaticproperty', payload);

				commit('addLoadingItem', 'languages');
			})
			.catch((error) => {
				this.errorMessage = error.message;
				console.error('There was an error!', error);
			});
	},
	fetchuseractivities({commit}) {
		if (store.state.config.debug == 1) {
			console.log('fetch useractivities');
		}

		var post = new Object();
		post.task = 'getuseractivities';

		axios.post(store.state.config.apiserver + 'app-actions.php', post)
			.then((response) => {
				//console.log(response.data) ;

				const payload = new Object();
				payload.property = 'useractivities';
				payload.value = response.data;

				commit('setstaticproperty', payload);

				commit('addLoadingItem', 'useractivities');
			})
			.catch((error) => {
				this.errorMessage = error.message;
				console.error('There was an error!', error);
			});
	},
	fetchuserfunctions({commit}) {
		if (store.state.config.debug == 1) {
			console.log('fetch userfunctions');
		}

		var post = new Object();
		post.task = 'getuserfunctions';

		axios.post(store.state.config.apiserver + 'app-actions.php', post)
			.then((response) => {
				//console.log(response.data) ;

				const payload = new Object();
				payload.property = 'userfunctions';
				payload.value = response.data;

				commit('setstaticproperty', payload);

				commit('addLoadingItem', 'userfunctions');
			})
			.catch((error) => {
				this.errorMessage = error.message;
				console.error('There was an error!', error);
			});
	},
	fetchpostcats({commit}) {
		if (store.state.config.debug == 1) {
			console.log('fetch postcats');
		}

		var post = new Object();
		post.task = 'getpostcats';

		axios.post(store.state.config.apiserver + 'app-actions.php', post)
			.then((response) => {
				// console.log(response.data);
				const payload = new Object();
				payload.property = 'postcats';
				payload.value = response.data;

				commit('setstaticproperty', payload);

				commit('addLoadingItem', 'postcats');
			})
			.catch((error) => {
				this.errorMessage = error.message;
				console.error('There was an error!', error);
			});
	},

	fetchlocations({commit}) {
		if (store.state.config.debug == 1) {
			console.log('fetch locations');
		}

		var post = new Object();
		post.task = 'getlocations';
		axios.post(store.state.config.apiserver + 'app-actions.php', post)
			.then((response) => {
				//console.log(response.data) ;
				const payload = new Object();
				payload.property = 'locations';
				payload.value = response.data;

				commit('setstaticproperty', payload);

				commit('addLoadingItem', 'locations');
			})
			.catch((error) => {
				this.errorMessage = error.message;
				console.error('There was an error!', error);
			});
	},
	fetchtranslations({commit}) {
		if (store.state.config.debug == 1) {
			console.log('fetch translations');
		}

		var language = store.state.active.languagewebsite;

		var post = new Object();
		post.task = 'gettranslations';

		axios.post(store.state.config.apiserver + 'app-actions.php', post)
			.then((response) => {
				var translate = new Object();

				if (response.data) {
					response.data.forEach(function (translation) {
						translate[translation.code] = translation[language].replace(/(\\n)+/g, '<br />');
					});

					commit('settranslate', translate);

					commit('addLoadingItem', 'translations');
				}
			})
			.catch((error) => {
				this.errorMessage = error.message;
				console.error('There was an error!', error);
			});
	},

	fetchuser({commit, dispatch}) {
		if (store.state.config.debug == 1) {
			console.log('fetchuser');
		}

		const hdaccesstoken = document.cookie
			.split('; ')
			.find((row) => row.startsWith('hockeydo'))
			.split('=')[1];

		var post = new Object();
		post.task = 'fetchuser';
		post.hdaccesstoken = hdaccesstoken;

		axios.post(store.state.config.apiserver + 'app-actions.php', post)
			.then((response) => {
				console.log('fetch user');

				var user = response.data;

				user.languagewrite = store.state.staticdata.languages.find((obj) => obj.id === user.languagewriteid);
				user.location = store.state.staticdata.locations.find((obj) => obj.id === user.locationid);

				commit('setuser', user);

				dispatch('fetchuserclub');

				var postlanguage = store.state.staticdata.languages.find((obj) => obj.id === store.state.user.languagewriteid);
				commit('setactiveproperty', {property: 'postlanguage', value: postlanguage});

				var postlocation = store.state.staticdata.locations.find((obj) => obj.id === store.state.user.locationid);
				commit('setactiveproperty', {property: 'postlocation', value: postlocation});
				commit('setactiveproperty', {property: 'filterlocation', value: postlocation});

				var languagewebsite = store.state.user.languagewebsite;

				commit('setactiveproperty', {property: 'languagewebsite', value: languagewebsite});
			})
			.catch((error) => {
				this.errorMessage = error.message;
				console.error('There was an error!', error);
			});
	},

	fetchtimelinerules({commit}) {
		if (store.state.config.debug == 1) {
			console.log('fetch timelinerules');
		}

		var post = new Object();
		post.task = 'fetchtimelinerules';

		//console.log(post);

		axios.post(store.state.config.apiserver + 'app-actions.php', post)
			.then((response) => {
				const payload = new Object();
				payload.property = 'rules';
				payload.value = response.data;
				commit('sethomeproperty', payload);

				commit('addLoadingItem', 'timelinerules');
			})
			.catch((error) => {
				this.errorMessage = error.message;
				console.error('There was an error!', error);
			});
	},
	fetchuserclub({commit}) {
		if (store.state.config.debug == 1) {
			console.log('fetch userclub');
		}

		var post = new Object();
		post.task = 'fetchuserclub';
		post.clubid = store.state.user.clubid;

		axios.post(store.state.config.apiserver + 'app-actions.php', post)
			.then((response) => {
				// console.log(response.data);

				commit('setUserClub', response.data);
			})
			.catch((error) => {
				this.errorMessage = error.message;
				console.error('There was an error!', error);
			});
	},

	fetchManual({commit}) {
		if (store.state.config.debug == 1) {
			console.log('fetch manual');
		}

		var post = new Object();
		post.task = 'fetchmanual';

		axios.post(store.state.config.apiserver + 'app-actions.php', post)
			.then((response) => {
				// console.log(response.data);

				const payload = new Object();
				payload.property = 'manuals';
				payload.value = response.data;

				commit('setstaticproperty', payload);

				commit('addLoadingItem', 'manual');
			})
			.catch((error) => {
				this.errorMessage = error.message;
				console.error('There was an error!', error);
			});
	},

	fetchContinents({commit}) {
		if (store.state.config.debug == 1) {
			console.log('fetch continents');
		}

		var post = new Object();
		post.task = 'fetchcontinents';

		axios.post(store.state.config.apiserver + 'app-actions.php', post)
			.then((response) => {
				const payload = new Object();
				payload.property = 'continents';
				payload.value = response.data;

				commit('setstaticproperty', payload);

				commit('addLoadingItem', 'continents');
			})
			.catch((error) => {
				this.errorMessage = error.message;
				console.error('There was an error!', error);
			});
	},
	fetchCountries({commit}) {
		if (store.state.config.debug == 1) {
			console.log('fetch countries');
		}

		var post = new Object();
		post.task = 'fetchcountries';

		axios.post(store.state.config.apiserver + 'app-actions.php', post)
			.then((response) => {
				// console.log(response.data);
				const payload = new Object();
				payload.property = 'countries';
				payload.value = response.data;

				commit('setstaticproperty', payload);

				commit('addLoadingItem', 'countries');
			})
			.catch((error) => {
				this.errorMessage = error.message;
				console.error('There was an error!', error);
			});
	},

	fetchRegions({commit}) {
		if (store.state.config.debug == 1) {
			console.log('fetch regions');
		}

		var post = new Object();
		post.task = 'fetchregions';

		axios.post(store.state.config.apiserver + 'app-actions.php', post)
			.then((response) => {
				// console.log(response.data);
				const payload = new Object();
				payload.property = 'regions';
				payload.value = response.data;

				commit('setstaticproperty', payload);

				commit('addLoadingItem', 'regions');
			})
			.catch((error) => {
				this.errorMessage = error.message;
				console.error('There was an error!', error);
			});
	},

	handleClubsRoute: async ({commit, state}, params) => {
		if (state.config.debug == 1) {
			console.log('handleClubsRoute');
		}

		const continent = state.staticdata.continents.find((item) => item.slug === params.continent);
		commit('setCurrentContinent', continent);

		const country = state.staticdata.countries.find((item) => item.slug === params.country);
		commit('setCurrentCountry', country);

		const region = state.staticdata.regions.find((item) => item.slug === params.region);
		commit('setCurrentRegion', region);

		if (store.state.config.debug == 1) {
			console.log('fetch club');
		}

		// console.log(params);

		const payload = new Object();
		payload.property = 'club';
		payload.value = [];

		commit('setcurrentproperty', payload);

		var post = new Object();
		post.task = 'fetchclub';
		post.clubslug = params.club;

		return axios
			.post(store.state.config.apiserver + 'app-actions.php', post)
			.then((response) => {
				// console.log(response.data);
				const payload = new Object();
				payload.property = 'club';
				payload.value = response.data;

				commit('setcurrentproperty', payload);
			})
			.catch((error) => {
				this.errorMessage = error.message;
				console.error('There was an error!', error);
			});
	},
};
