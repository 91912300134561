import axios from 'axios';

import store from '../store';

export default {
	fetchpostids({commit}) {
		if (store.state.config.debug == 1) {
			console.log('posts fetch postids');
		}

		var batch = store.state.posts.postbatch;

		var post = new Object();
		post.task = 'getpostids';

		post.batch = batch;

		post.scope = '';
		post.languageids = store.state.active.filterlanguagesids;
		post.locationid = store.state.active.filterlocation.id;
		post.section = store.state.active.section.toLowerCase();
		
		
		post.hdaccesstoken = store.state.user?.hdaccesstoken || null;
		

		if (store.state.route.section == 'posts') {
			if (store.state.active.filtercat) {
				post.postcatid = store.state.active.filtercat.id;
			}

			// // Filter op scope

			var scope = store.state.route.scope;

			if (scope == 'club') {
				post.clubslug = store.state.route.club;
				post.scope = scope;
			}

			if (scope == 'region') {
				post.regionslug = store.state.route.region;
				post.scope = scope;
			}

			if (scope == 'country') {
				post.countryslug = store.state.route.country;
				post.scope = scope;
			}

			if (scope == 'continent') {
				post.continentslug = store.state.route.continent;
				post.scope = scope;
			}

			if (scope == 'world') {
				post.worldid = 1;
			}
		}

		if (store.state.active.section == 'games') {
			post.itemid = store.state.current.video.id;
		}

		if (store.state.active.section == 'we') {
			if (store.state.we.group) {
				post.itemid = store.state.we.group.id;
			}
		}

		// console.log(post);

		commit('setpostloading', true);

		axios.post(store.state.config.apiserver + 'app-actions.php', post)
			.then((response) => {
				var postsids = response.data.postids;
				
				// console.log(response.data);

				// console.log(response.data.query);
				// console.log(response.data.debug);

				commit('addpostsids', postsids);

				batch++;

				commit('setpostbatch', batch);

				commit('setpostloading', false);
			})
			.catch((error) => {
				this.errorMessage = error.message;
				console.error('There was an error!', error);
			});
	},

	handlePostsRoute: async ({commit, state}, params) => {
		if (state.config.debug == 1) {
			console.log('handlePostsRoute');
		}



		const continent = state.staticdata.continents.find((item) => item.slug === params.continent);
		commit('setCurrentContinent', continent);

		const country = state.staticdata.countries.find((item) => item.slug === params.country);
		commit('setCurrentCountry', country);

		const region = state.staticdata.regions.find((item) => item.slug === params.region);
		commit('setCurrentRegion', region);
		
		

		if (!state.current.club || params.club != state.current.club.slug) {
			if (store.state.config.debug == 1) {
				console.log('fetch club');
			}

			const payload = new Object();
			payload.property = 'club';
			payload.value = [];

			commit('setcurrentproperty', payload);

			var post = new Object();
			post.task = 'fetchclub';
			post.clubslug = params.club;

			return axios
				.post(store.state.config.apiserver + 'app-actions.php', post)
				.then((response) => {
					// console.log(response.data);
					const payload = new Object();
					payload.property = 'club';
					payload.value = response.data;

					commit('setcurrentproperty', payload);
				})
				.catch((error) => {
					this.errorMessage = error.message;
					console.error('There was an error!', error);
				});
		}
	},
};
