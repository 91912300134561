<template>
	<div :class="`extra-class ${openmap ? 'open' : 'close'}`">
		<div class="map-container">
			<div id="map1"></div>

			<div class="search">
				<input v-model="searchstring" :placeholder="translate.SEARCH_ZOEKOPCLUB" />

				<i v-on:click="clearsearch()" class="clearsearch bi bi-x tekstgrijs"></i>
			</div>
		</div>

		<i v-on:click="closemap()" class="closemap bi bi-x tekstblauw"></i>

		<div class="filter">
			<div class="row mb5">
				<div class="col-10 middle">
					<div v-on:click="setactiveproperty('toppanel', 'FilterAreaAreaContinents')" class="select">{{ continent }}</div>
				</div>

				<div class="col-2 middle-right">
					<a class="bi bi-arrow-right-circle-fill tekstblauw" v-if="maps.continent?.id > 1" v-on:click="gotocontinent()"></a>
				</div>
			</div>

			<div class="row mb5" v-if="maps.continent?.id > 1">
				<div class="col-10 middle">
					<div v-on:click="setactiveproperty('toppanel', 'FilterAreaAreaCountries')" class="select">{{ country }}</div>
				</div>

				<div class="col-2 middle-right">
					<a v-if="maps.country?.id > 0" v-on:click="gotocountry()" class="bi bi-arrow-right-circle-fill tekstblauw"></a>
				</div>
			</div>

			<div class="row mb5" v-if="maps.country?.id > 0">
				<div class="col-10">
					<div v-on:click="setactiveproperty('toppanel', 'FilterAreaAreaRegions')" class="select">{{ region }}</div>
				</div>

				<div class="col-2 middle-right">
					<a v-if="maps.region?.id > 0" v-on:click="gotoregion()" class="bi bi-arrow-right-circle-fill tekstblauw"></a>
				</div>
			</div>

			<div class="row clubs" v-if="maps.region?.id > 0">
				<div class="col-10 middle">
					<div v-on:click="setactiveproperty('toppanel', 'FilterAreaAreaClubs')" class="select">{{ club }}</div>
				</div>
				<div v-if="userhasnoclub" class="col-2 middle-right">
					<a v-if="maps.club?.club_id > 0" v-on:click="gotoclub()" class="button rood ditismijnclub">{{ translate.MAP_DITISMIJNCLUB }}</a>
				</div>

				<div v-else class="col-2 middle-right">
					<a v-if="maps.club?.club_id > 0" v-on:click="gotoclub()" class="bi bi-arrow-right-circle-fill tekstblauw"></a>
				</div>
			</div>
		</div>

		<div v-if="userhasnoclub" class="userhasnoclub">
			{{ translate.MAP_SELECTUSERCLUB }}
		</div>
	</div>
</template>

<script>
import {mapState} from 'vuex';

import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster/dist/leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';

export default {
	data() {
		return {
			map: null,
			markers: [],
			currentclubid: null,
			clickedclub: null,
			clickmarker: null,
			isMapInitialized: false,
		};
	},

	methods: {
		clearsearch() {
			this.searchstring = '';
		},
		initMap() {
			if (this.$store.state.config.debug == 1) {
				console.log('initmap');
			}

			this.map1 = L.map('map1', {preferCanvas: true, zoomControl: true, zoom: 1, zoomAnimation: false, fadeAnimation: true, markerZoomAnimation: true}).setView([0, 0], 1); // Pas deze coördinaten aan naar je eigen voorkeur

			L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
				maxZoom: 19,
				attribution: '© OpenStreetMap contributors',
			}).addTo(this.map1);

			this.isMapInitialized = true; // Map is klaar
		},

		placemarkers() {
			if (this.$store.state.config.debug == 1) {
				console.log('placemarkers');
			}

			if (!this.isMapInitialized) {
				return;
			}

			if (!this.clubs || !Array.isArray(this.clubs)) {
				return;
			}

			// Verwijder bestaande markers als die er zijn
			if (this.markers) {
				this.map1.removeLayer(this.markers);
			}

			this.markers = L.markerClusterGroup({
				chunkedLoading: true,
				maxClusterRadius: 50,
			});

			var bounds = L.latLngBounds();

			this.clubs.forEach((club) => {
				var geoposition = club.club_geoposition.split(',');
				var lat = geoposition['0'];
				var lng = geoposition['1'];
				// console.log(club);
				if (lat >= -90 && lat <= 90 && lng >= -180 && lng <= 180) {
					var iconUrl = club.noclub == '1' ? 'https://www.hockeydo.com/images/marker-rood.png' : 'https://www.hockeydo.com/images/marker.png';
					const marker = L.marker([lat, lng], {
						icon: L.divIcon({
							className: 'custom-div-icon',
							html: `<div style="background-image: url('${iconUrl}');" class="marker-pin"></div><span class="marker-text">${club.club_nameshort}</span>`,
							iconSize: [40, 40],
							iconAnchor: [20, 40],
						}),
					});
					marker.on('click', () => {
						this.clickonmarker(club);
					});
					this.markers.addLayer(marker);
					bounds.extend(marker.getLatLng());
				}
			});
			this.map1.addLayer(this.markers);
			setTimeout(() => {
				this.map1.fitBounds(bounds, {animate: false});
			}, 500);
			// Voeg een zoomend event listener toe om labels te tonen/verbergen
			this.map1.on(
				'zoomend',
				function () {
					const currentZoom = this.map1.getZoom();
					const labelElements = document.querySelectorAll('.marker-text');
					labelElements.forEach((label) => {
						if (currentZoom > 7) {
							// Pas dit zoomniveau aan naar wens
							label.style.display = 'block';
						} else {
							label.style.display = 'none';
						}
					});
				}.bind(this)
			);
		},

		clickonmarker(club) {
			if (this.userhasnoclub) {
				this.setactiveproperty('setuserclub', club);
				this.setactiveproperty('toppanel', 'SetUserClubConfirmation');
			} else {
				this.setactiveproperty('screen', null);
				this.setactiveproperty('searchstring', '');
				this.$router.push({name: 'Clubs', params: {continent: club.continent_slug, country: club.country_slug, region: club.region_slug, club: club.club_slug, scope: 'clubinfo', sub: 'info', subsub: 'info'}});
			}
		},

		gotocontinent() {
			if (this.$store.state.maps.continent?.id > 0) {
				// We zijn in een continent

				this.$router.push({name: 'Posts', params: {continent: this.$store.state.maps.continent.slug, country: '-', region: '-', club: '-', scope: 'continent', sub: 'posts', subsub: 'all'}});
				this.setactiveproperty('panel', '');
				this.setactiveproperty('screen', null);
				this.setactiveproperty('searchstring', '');
			} else {
				this.$router.push({name: 'Posts', params: {continent: '-', country: '-', region: '-', club: '-', scope: 'world', sub: 'posts', subsub: 'all'}});
				this.setactiveproperty('panel', '');
				this.setactiveproperty('screen', null);
				this.setactiveproperty('searchstring', '');
			}
		},
		gotocountry() {
			var continentslug = this.$store.state.maps.continent.slug;
			var countryslug = this.$store.state.maps.country.slug;

			this.$router.push({name: 'Posts', params: {continent: continentslug, country: countryslug, region: '-', club: '-', scope: 'country', sub: 'posts', subsub: 'all'}});
			this.setactiveproperty('panel', '');
			this.setactiveproperty('screen', null);
			this.setactiveproperty('searchstring', '');
		},
		gotoregion() {
			var continentslug = this.$store.state.maps.continent.slug;
			var countryslug = this.$store.state.maps.country.slug;
			var regionslug = this.$store.state.maps.region.slug;

			this.$router.push({name: 'Posts', params: {continent: continentslug, country: countryslug, region: regionslug, club: '-', scope: 'region', sub: 'posts', subsub: 'all'}});

			this.setactiveproperty('toppanel', '');
			this.setactiveproperty('screen', null);
			this.setactiveproperty('searchstring', '');
		},
		gotoclub() {
			var continentslug = this.$store.state.maps.continent.slug;
			var countryslug = this.$store.state.maps.country.slug;
			var regionslug = this.$store.state.maps.region.slug;
			var clubslug = this.$store.state.maps.club.club_slug;

			this.$router.push({name: 'Posts', params: {continent: continentslug, country: countryslug, region: regionslug, club: clubslug, scope: 'club', sub: 'posts', subsub: 'all'}});

			this.setactiveproperty('panel', '');
			this.setactiveproperty('screen', null);
			this.setactiveproperty('searchstring', '');
		},
		closemap() {
			this.setactiveproperty('screen', '');
			this.setactiveproperty('searchstring', '');
		},
	},
	computed: {
		...mapState(['current', 'active', 'config', 'maps']),

		clubs() {
			return this.maps.clubs;
		},

		searchstring: {
			get() {
				return this.$store.state.active.searchstring;
			},
			set(value) {
				var obj = new Object();
				obj.property = 'searchstring';
				obj.value = value;
				this.$store.commit('setactiveproperty', obj);
			},
		},

		openmap() {
			if (this.$store.state.user) {
				if (this.$store.state.user.clubid > 0) {
					return this.$store.state.active.screen === 'Map' ? true : false;
				} else {
					return true;
				}
			} else {
				return this.$store.state.active.screen === 'Map' ? true : false;
			}
		},

		currentscope() {
			if (this.$store.state.active.filterareaclub) {
				return 'club';
			}

			if (this.$store.state.active.filterarearegion) {
				return 'region';
			}

			if (this.$store.state.active.filterareacountry) {
				return 'country';
			}

			if (this.$store.state.active.filterareacontinent) {
				if (this.$store.state.active.filterareacontinent.id == 0) {
					return 'world';
				} else {
					return 'continent';
				}
			} else {
				return 'world';
			}
		},
		continent() {
			if (this.maps.continent) {
				return this.maps.continent[this.active.languagewebsite];
			} else {
				return this.translate.WORLD;
			}
		},

		country() {
			if (this.maps.country) {
				return this.maps.country[this.active.languagewebsite];
			} else {
				return this.translate.FILTER_KIESLAND;
			}
		},

		region() {
			if (this.maps.region) {
				return this.maps.region.name;
			} else {
				return this.translate.FILTER_KIESREGIO;
			}
		},

		club() {
			if (this.maps.club) {
				return this.maps.club.club_nameshort;
			} else {
				return this.translate.FILTER_KIESCLUB;
			}
		},
		userhasnoclub() {
			if (this.$store.state.user) {
				if (this.$store.state.user.id > 0) {
					if (this.$store.state.user.clubid < 1) {
						return true;
					}
				} else {
					return false;
				}
			} else {
				return false;
			}

			return false;
		},
	},
	watch: {
		clubs() {
			this.placemarkers();
		},
	},

	mounted() {
		this.$nextTick(() => {
			this.initMap();
			this.placemarkers();
		});
	},
};
</script>

<style>
#map1 {
	position: fixed;
	left: 0px;
	right: 0;
	top: 65px;
	bottom: 0px;
	background: #aad3df;
	opacity: 1;
	z-index: 5;
	transition: opacity 0.1s;
	display: block;
}

.close #map1 {
	opacity: 0;
	z-index: -100;
}

.search {
	position: fixed;
	top: 75px;
	right: 65px;
	left: 10px;
	z-index: 10;
}

.search .clearsearch {
	position: absolute;
	right: 10px;
	top: 5px;
}

.userhasnoclub {
	position: fixed;
	z-index: 10;
	bottom: 60px;
	left: 50%;
	transform: translateX(-50%);
	background: var(--rood);
	color: var(--wit);
	padding: 10px 15px;
	border-radius: 25px;
	margin-bottom: 10px;
	white-space: nowrap;
}

.filter {
	position: fixed;
	top: 120px;
	left: 10px;
	width: 200px;
	z-index: 6;
}

.close .filter {
	opacity: 0;
	z-index: -10;
}

.closemap {
	position: fixed;
	top: 75px;
	right: 15px;
	z-index: 6;
	font-size: 40px !important;
	background: #fff;
	border-radius: 50%;
	opacity: 1;
}

.close .map-container {
	z-index: -1;
	opacity: 0;
	position: relative;
}

.close .closemap {
	z-index: 0;
	opacity: 0;
}

.custom-div-icon {
	background: transparent;
	border: none;
}

.marker-pin {
	width: 40px;
	height: 40px;
	background-size: cover;
}

.marker-text {
	position: absolute;
	top: 5px;
	left: 24px;
	white-space: nowrap;
	display: none; /* standaard verborgen */
	background: #3bb58e;
	padding: 3px 7px;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	color: #fff;
	font-size: 11px;
	line-height: 11px;
}

.ditismijnclub {
	position: relative;
	left: 100px;
}

/* SM */
@media (min-width: 576px) {
}

/* MD */
@media (min-width: 768px) {
	.search {
		position: fixed;
		top: 75px;
		left: 50%;
		transform: translateX(-50%);
		width: 400px;
		z-index: 10;
	}

	.filter {
		position: fixed;
		top: 75px;
		left: 60px;
		width: 200px;
	}
}

/* LG */
@media (min-width: 992px) {
}

/* XL */
@media (min-width: 1200px) {
}
</style>
