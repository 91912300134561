<template>
	<div id="PlaysPlay" class="view game">
		<div v-if="showshirts">
			<div class="shirts">
				<div class="shirtcontainer">
					<canvas class="shirt" id="team_1_shirt"></canvas>
					<div class="tekst">{{ translate.GAME_TEAM1 }}</div>
				</div>

				<div class="shirtcontainer">
					<canvas class="shirt" id="team_2_shirt"></canvas>
					<div class="tekst">{{ translate.GAME_TEAM2 }}</div>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col col-12">
				<div class="vimeocontainer">
					<iframe class="vimeo" :src="vimeosrc" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
				</div>
			</div>

			<div class="col col-12">
				<h1>
					{{ current.competition['title_' + languagewebsite] }}
					<i class="bi bi-caret-right-fill"></i>
					{{ translate.GAME_SPEL }} {{ current.game.game }}
					<i class="bi bi-caret-right-fill"></i>
					{{ translate.GAME_VIDEO }} {{ current.video.nummer }}
				</h1>

				<div v-if="!videoplayed">
					<div v-if="games?.play_team">
						<div v-on:click="openpanel('PlaysPlayTeams')" class="select grijs mb10">{{ this.$store.state.games.play_team[this.$store.state.active.languagewebsite] }}</div>
					</div>
					<div v-else>
						<div v-on:click="openpanel('PlaysPlayTeams')" class="select mb10">{{ translate.GAME_FLUITTEGEN }}</div>
					</div>

					<div v-if="games?.play_overtreding">
						<div v-on:click="openpanel('PlaysPlayOvertredingen')" class="select grijs mb10">{{ this.$store.state.games.play_overtreding[this.$store.state.active.languagewebsite] }}</div>
					</div>
					<div v-else>
						<div v-on:click="openpanel('PlaysPlayOvertredingen')" class="select mb10">{{ translate.GAME_OVERTREDING }}</div>
					</div>

					<div v-if="games?.play_kaart">
						<div v-on:click="openpanel('PlaysPlayKaarten')" class="select grijs mb10">{{ this.$store.state.games.play_kaart[this.$store.state.active.languagewebsite] }}</div>
					</div>
					<div v-else>
						<div v-on:click="openpanel('PlaysPlayKaarten')" class="select mb10">{{ translate.GAME_KAART }}</div>
					</div>

					<div v-if="games?.play_straf">
						<div v-on:click="openpanel('PlaysPlayStraffen')" class="select grijs mb10">{{ this.$store.state.games.play_straf[this.$store.state.active.languagewebsite] }}</div>
					</div>
					<div v-else>
						<div v-on:click="openpanel('PlaysPlayStraffen')" class="select mb10">{{ translate.GAME_STRAF }}</div>
					</div>

					<div>
						<a v-if="canplay == true" v-on:click="play()" class="button arrow-right floatright mb10">{{ translate.GAME_VOLGENDEVIDEO }}</a>
						<a v-if="canplay == false" class="button grijs arrow-right floatright mb10">{{ translate.GAME_EERSTSPELEN }}</a>
					</div>
				</div>

				<div v-if="videoplayed">
					{{ translate.GAME_GAMEALLREADYPLAYED }}
					<br />

					<a v-on:click="skip()" class="button arrow-right floatright mb10">{{ translate.GAME_VOLGENDEVIDEO }}</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
import {mapState} from 'vuex';

export default {
	data() {
		return {
			videonummer: 1,
			showshirts: true,
		};
	},

	methods: {
		openpanel(panel) {
			this.setactiveproperty('panel', panel);
		},

		skip() {
			this.$router.push({
				name: 'Games',
				params: {
					competition: this.current.competition.slug,
					game: 'game-' + this.current.game.game,
					video: 'video-' + this.nextVideoNumber,
					mode: this.current.mode,
				},
			});
		},

		play() {
			if (this.loggedin == false) {
				this.setactiveproperty('panel', 'Login');
			}

			if (this.loggedin == true) {
				if (this.config.debug == 1) {
					console.log('play');
				}

				var post = new Object();
				post.task = 'play';
				post.hdaccesstoken = this.$cookie.get('hockeydo');
				post.video = this.current.video.id;
				post.team = this.games.play_team.id;
				post.overtreding = this.games.play_overtreding.id;
				post.kaart = this.games.play_kaart.id;
				post.straf = this.games.play_straf.id;

				axios.post(this.config.apiserver + './games/views/PlaysPlay.php', post)
					.then((response) => {
						this.response = response.data;

						if (this.$store.state.config.debug == 1) {
							console.log(response.data);
						}

						this.$store.dispatch('fetchcompetitions');

						this.setgamesproperty('play_kaart', null);
						this.setgamesproperty('play_overtreding', null);
						this.setgamesproperty('play_straf', null);
						this.setgamesproperty('play_team', null);

						this.$router.push({
							name: 'Games',
							params: {
								competition: this.current.competition.slug,
								game: 'game-' + this.current.game.game,
								video: 'video-' + this.nextVideoNumber,
								mode: this.current.mode,
							},
						});
					})
					.catch((error) => {
						this.errorMessage = error.message;
						console.error('There was an error!', error);
					});
			}
		},

		drawshirt1() {
			// Shirt 1 ---------------------------------------------------

			var c = document.getElementById('team_1_shirt');

			var ctx = c.getContext('2d');

			c.width = 300;
			c.height = 150;

			ctx.clearRect(0, 0, 300, 150);

			ctx.save();
			ctx.miterLimit = 4;
			ctx.scale(1.6, 0.7);
			ctx.save();

			ctx.translate(-21.874997, -690.12578);
			ctx.save();

			ctx.strokeStyle = '#000000';
			ctx.lineWidth = 1;

			// links
			ctx.fillStyle = this.video.team_1_kleur_shirt_1;
			ctx.beginPath();
			ctx.moveTo(104.25, 713.519);
			ctx.bezierCurveTo(115.512, 715.031, 124.894, 714.663, 133.5, 713.519);
			ctx.lineTo(138.75, 720.769);
			ctx.lineTo(76, 853.269);
			ctx.lineTo(76, 765.019);
			ctx.bezierCurveTo(69.259, 765.77, 63.658, 765.219, 52.5, 771.019);
			ctx.bezierCurveTo(44.124, 764.034, 41.509, 752.567, 39, 741.019);
			ctx.bezierCurveTo(64.95400000000001, 729.349, 78.005, 727.7570000000001, 99.25, 721.269);
			ctx.lineTo(104.25, 713.519);
			ctx.closePath();
			ctx.fill();
			ctx.stroke();

			// rechts

			ctx.fillStyle = this.video.team_1_kleur_shirt_2;
			ctx.beginPath();
			ctx.moveTo(133.5, 713.519);
			ctx.lineTo(138.75, 720.769);
			ctx.bezierCurveTo(161.394, 729.352, 174.516, 728.415, 198.75, 741.769);
			ctx.bezierCurveTo(195.631, 752.255, 195.044, 763.753, 185.75, 771.769);
			ctx.bezierCurveTo(178, 765.681, 170.25, 766.969, 162.5, 765.019);

			ctx.lineTo(162.5, 853.269);
			ctx.bezierCurveTo(133.372, 858.572, 104.463, 859.9350000000001, 76, 853.269);

			ctx.closePath();
			ctx.fill();
			ctx.stroke();

			// kraagje links

			ctx.fillStyle = this.video.team_1_kleur_kraag;
			ctx.beginPath();
			ctx.moveTo(103.75, 714.519);
			ctx.bezierCurveTo(106.49, 722.582, 110.43299999999999, 729.2, 116.5, 733.269);
			ctx.lineTo(109.5, 741.019);
			ctx.bezierCurveTo(101.767, 735.082, 101.32900000000001, 728.3340000000001, 99.25, 721.769);
			ctx.fill();
			ctx.stroke();

			// kraag rechts

			ctx.beginPath();
			ctx.moveTo(133.75, 714.019);
			ctx.bezierCurveTo(131.01, 722.082, 127.06700000000001, 728.7, 121, 732.769);
			ctx.lineTo(128, 740.519);
			ctx.bezierCurveTo(135.733, 734.582, 136.171, 727.8340000000001, 138.25, 721.269);
			ctx.fill();
			ctx.stroke();

			// Knoopjes
			ctx.fillStyle = 'rgba(0,0,0,0)';
			ctx.strokeStyle = '#000000';
			ctx.lineCap = 'round';
			ctx.font = '   15px ';
			ctx.beginPath();
			ctx.moveTo(116.75, 733.019);
			ctx.lineTo(120.5, 733.019);
			ctx.lineTo(120.5, 756.269);

			ctx.closePath();
			ctx.fill();
			ctx.stroke();
			ctx.restore();
		},

		drawshirt2() {
			// Shirt 2 ---------------------------------------------------

			var c = document.getElementById('team_2_shirt');

			var ctx = c.getContext('2d');

			c.width = 300;
			c.height = 150;

			ctx.clearRect(0, 0, 300, 150);

			ctx.save();
			ctx.miterLimit = 4;
			ctx.scale(1.6, 0.7);
			ctx.save();

			ctx.translate(-21.874997, -690.12578);
			ctx.save();

			ctx.strokeStyle = '#000000';
			ctx.lineWidth = 1;

			// links
			ctx.fillStyle = this.video.team_2_kleur_shirt_1;
			ctx.beginPath();
			ctx.moveTo(104.25, 713.519);
			ctx.bezierCurveTo(115.512, 715.031, 124.894, 714.663, 133.5, 713.519);
			ctx.lineTo(138.75, 720.769);
			ctx.lineTo(76, 853.269);
			ctx.lineTo(76, 765.019);
			ctx.bezierCurveTo(69.259, 765.77, 63.658, 765.219, 52.5, 771.019);
			ctx.bezierCurveTo(44.124, 764.034, 41.509, 752.567, 39, 741.019);
			ctx.bezierCurveTo(64.95400000000001, 729.349, 78.005, 727.7570000000001, 99.25, 721.269);
			ctx.lineTo(104.25, 713.519);
			ctx.closePath();
			ctx.fill();
			ctx.stroke();

			// rechts
			ctx.fillStyle = this.video.team_2_kleur_shirt_2;
			ctx.beginPath();
			ctx.moveTo(133.5, 713.519);
			ctx.lineTo(138.75, 720.769);
			ctx.bezierCurveTo(161.394, 729.352, 174.516, 728.415, 198.75, 741.769);
			ctx.bezierCurveTo(195.631, 752.255, 195.044, 763.753, 185.75, 771.769);
			ctx.bezierCurveTo(178, 765.681, 170.25, 766.969, 162.5, 765.019);

			ctx.lineTo(162.5, 853.269);
			ctx.bezierCurveTo(133.372, 858.572, 104.463, 859.9350000000001, 76, 853.269);

			ctx.closePath();
			ctx.fill();
			ctx.stroke();

			// kraagje links

			ctx.fillStyle = this.video.team_2_kleur_kraag;
			ctx.beginPath();
			ctx.moveTo(103.75, 714.519);
			ctx.bezierCurveTo(106.49, 722.582, 110.43299999999999, 729.2, 116.5, 733.269);
			ctx.lineTo(109.5, 741.019);
			ctx.bezierCurveTo(101.767, 735.082, 101.32900000000001, 728.3340000000001, 99.25, 721.769);
			ctx.fill();
			ctx.stroke();

			// kraag rechts

			ctx.beginPath();
			ctx.moveTo(133.75, 714.019);
			ctx.bezierCurveTo(131.01, 722.082, 127.06700000000001, 728.7, 121, 732.769);
			ctx.lineTo(128, 740.519);
			ctx.bezierCurveTo(135.733, 734.582, 136.171, 727.8340000000001, 138.25, 721.269);
			ctx.fill();
			ctx.stroke();

			// Knoopjes
			ctx.fillStyle = 'rgba(0,0,0,0)';
			ctx.strokeStyle = '#000000';
			ctx.lineCap = 'round';
			ctx.font = '   15px ';
			ctx.beginPath();
			ctx.moveTo(116.75, 733.019);
			ctx.lineTo(120.5, 733.019);
			ctx.lineTo(120.5, 756.269);

			ctx.closePath();
			ctx.fill();
			ctx.stroke();
			ctx.restore();
		},
	},

	computed: {
		...mapState(['current', 'competitions', 'config', 'games', 'staticdata']),

		video() {
			return this.current.video;
		},

		vimeosrc() {
			if (this.current.video) {
				var vimeosrc = 'https://player.vimeo.com/video/' + this.current.video.video_url.split('/')['3'];

				return vimeosrc;
			} else {
				return null;
			}
		},

		canplay() {
			// Zijn alle keuzes gemaakt?

			if (this.$store.state.games?.play_team && this.$store.state.games.play_overtreding && this.$store.state.games.play_kaart && this.$store.state.games.play_straf) {
				return true;
			} else {
				return false;
			}
		},

		nextVideoNumber() {
			var next = Number(this.current.video.nummer) + 1;

			var videoid = this.current.game['video_' + next];

			if (videoid == 0) {
				return Number(this.current.video.nummer);
			} else {
				return next;
			}
		},
		videoplayed() {
			const currentvideonumber = Number(this.current.video.nummer);

			const videoplayed = this.current.game['videoplayed_' + currentvideonumber];

			return videoplayed;
		},
	},
	watch: {},
	mounted() {
		this.drawshirt1();
		this.drawshirt2();
	},
};
</script>

<style scoped>
.game .vimeocontainer {
	margin-top: -25px;
	margin-left: -15px;
	margin-right: -15px;
	position: relative;
	padding-bottom: 62%;
	margin-bottom: 15px;
}

.game .vimeocontainer iframe {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
}

.shirts {
	position: absolute;
	right: 15px;
	top: 0px;
	z-index: 1;
	background: rgba(0, 0, 0, 0.1);
	padding: 5px;
}

.shirtcontainer {
	width: 50%;
	float: left;
	text-align: center;
	color: #fff;
}

.shirtcontainer .tekst {
	text-transform: lowercase;
}

.game .shirt {
	width: 50px;
	height: 50px;
	margin-bottom: -10px;
}

.game select {
	color: #7a9fdd;
}

.game select.ok {
	color: #22baa6;
}

.dankje {
	height: 50vh;
}

/* SM */
@media (min-width: 576px) {
}

/* MD */
@media (min-width: 768px) {
	.game .shirt {
		width: 75px;
		height: 75px;
	}
}

/* LG */
@media (min-width: 992px) {
}

/* XL */
@media (min-width: 1200px) {
}
</style>
