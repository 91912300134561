<template>
	<div class="manual">
		<div class="label main" @click="manualopen = !manualopen">
			<i class="icon bi bi-book"></i>
			{{ translate.MANUAL_TITLE }}
			<i class="bi bi-chevron-down"></i>
		</div>

		<div v-if="manualopen">
			<div v-for="(manual, index) in manuals" v-bind:key="manual.id" class="box-shadow">
				<div class="label" :class="{open: activemanual === index}" @click="openpanel(index)">
					<i :class="`icon bi ${manual.icon}`"></i>

					{{ manual['label_' + this.active.languagewebsite] }}

					<i class="bi bi-chevron-down"></i>
				</div>

				<div class="content-wrapper" :class="{open: activemanual === index}">
					<div class="content" v-html="manual[this.active.languagewebsite]"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {mapState} from 'vuex';

export default {
	data() {
		return {
			activemanual: null,
			manualopen: false,
		};
	},

	components: {},
	computed: {
		...mapState(['active', 'staticdata']),

		manuals() {
			return this.staticdata.manuals;
		},
	},
	watch: {},

	methods: {
		openpanel(index) {
			if (index == this.activemanual) {
				this.activemanual = null;
			} else {
				this.activemanual = index;
			}
		},
	},
};
</script>

<style scoped>
.manual {
	margin-bottom: 10px;
}

.label {
	background: #fff;
	height: 40px;
	border-radius: 10px;
	line-height: 40px;
	padding: 0 15px;
	cursor: pointer;
	position: relative;
	font-weight: 600;
	padding-left: 50px;
}

.label.main {
	margin-bottom: 5px;
}

.label .icon {
	position: absolute;
	left: 10px;
	top: 0;

	color: var(--groen);
}

.label .bi-chevron-down {
	position: absolute;
	right: 15px;
	top: 0px;
	color: var(--groen);
	font-size: 20px;
}

.label.open .bi-chevron-down {
	transform: rotate(180deg);
}

.label.open {
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
}

.content-wrapper {
	max-height: 0;
	overflow: hidden;
	margin-bottom: 5px;
	transition: all 0.6s;
}
.content-wrapper.open {
	max-height: 1000px;
	border-top: none;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	transition: all 0s;
}

.content {
	background: #fff;
	padding: 15px;
}
</style>
