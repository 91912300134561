<template>
	<transition name="slideup">
		<div v-if="this.$store.state.active.panel == panelname" class="panel autoheight">
			<div v-if="this.$store.state.config.debug == 1" class="debug">Panel | {{ panelname }}</div>

			<div class="row panel_header">
				<div class="col col-6 middle">
					<h1>{{ translate.POSTEDIT_KOP }}</h1>
				</div>
				<div class="col-6 middle-right">
					<a v-on:click="setactiveproperty('panel', '')" class="bi bi-x tekstblauw floatright"></a>
				</div>
			</div>

			<div class="panel_content">
				<a v-if="this.$store.state.user.id == this.$store.state.active.item.userid" class="row mb10" v-on:click="deletepost()">
					<div class="col col-12 verticalcenter">
						<i class="bi bi-x-circle tekstgrijs mr10"></i>
						{{ translate.POSTEDIT_BERICHTVERWIJDEREN }}
					</div>
				</a>

				<a v-if="isadmin" class="row mb10" v-on:click="deletepost()">
					<div class="col col-12 verticalcenter">
						<i class="bi bi-x-circle tekstgrijs mr10"></i>
						{{ translate.POSTEDIT_BERICHTVERWIJDEREN }} door admin
					</div>
				</a>

				<a class="row mb10" v-on:click="bookmarkitem()">
					<div class="col col-12 verticalcenter">
						<div v-if="!bookmarkeditem">
							<i class="bi bi-bookmark tekstgrijs mr10"></i>
							{{ translate.POSTEDIT_OPSLAAN }}
						</div>
						<div v-if="bookmarkeditem">
							<i class="bi bi-bookmark-fill tekstgroen mr10"></i>
							{{ translate.POSTEDIT_OPGESLAGEN }}
						</div>
					</div>
				</a>

				<a class="row mb10" v-if="this.$store.state.active.item.type == 'post'" v-on:click="bookmarkuser()">
					<div class="col col-12 verticalcenter">
						<div v-if="!bookmarkeduser">
							<i class="bi bi-person-plus tekstgrijs mr10"></i>
							{{ itemuser }} {{ translate.POSTEDIT_VOLGEN }}
						</div>
						<div v-if="bookmarkeduser">
							<i class="bi bi-person-plus-fill tekstgroen mr10"></i>
							{{ itemuser }} {{ translate.POSTEDIT_ONTVOLGEN }}
						</div>
					</div>
				</a>

				<a class="row mb10" v-if="this.$store.state.active.item.type == 'feeditem'" v-on:click="bookmarkfeed()">
					<div class="col col-12 verticalcenter">
						<div v-if="!bookmarkeduser">
							<i class="bi bi-cloud-plus tekstgrijs mr10"></i>
							{{ this.$store.state.active.item.name }} {{ translate.POSTEDIT_VOLGEN }}
						</div>
						<div v-if="bookmarkeduser">
							<i class="bi bi-cloud-plus-fill tekstgroen mr10"></i>
							{{ this.$store.state.active.item.name }} {{ translate.POSTEDIT_ONTVOLGEN }}
						</div>
					</div>
				</a>
			</div>
		</div>
	</transition>
</template>

<script>
import axios from 'axios';

export default {
	data() {
		return {
			panelname: 'ItemMenu',
		};
	},

	methods: {
		bookmarkfeed() {
			if (this.$store.state.config.debug == 1) {
				console.log('bookmark feed');
			}

			var post = new Object();
			post.task = 'bookmark';
			post.itemid = this.$store.state.active.item.feedid;
			post.itemtype = 'feed';
			post.hdaccesstoken = this.$store.state.user.hdaccesstoken;

			//console.log(post);

			axios.post(this.$store.state.config.apiserver + 'interactions/panels/ItemMenu.php', post)
				.then((response) => {
					if (this.$store.state.config.debug == 1) {
						if (this.$store.state.config.debug == 1) {
							console.log(response.data);
						}

						this.setactiveproperty('task', 'refresh');
						this.setactiveproperty('taskitemtype', this.$store.state.active.item.type);
						this.setactiveproperty('taskitemid', this.$store.state.active.item.id);

						this.setactiveproperty('panel', null);
					}
				})
				.catch((error) => {
					this.errorMessage = error.message;
					console.error('There was an error!', error);
				});
		},

		bookmarkuser() {
			if (this.$store.state.config.debug == 1) {
				console.log('bookmark user');
			}

			var post = new Object();
			post.task = 'bookmark';
			post.itemid = this.$store.state.active.item.userid;
			post.itemtype = 'user';
			post.hdaccesstoken = this.$store.state.user.hdaccesstoken;

			//console.log(post);

			axios.post(this.$store.state.config.apiserver + 'interactions/panels/ItemMenu.php', post)
				.then((response) => {
					if (this.$store.state.config.debug == 1) {
						if (this.$store.state.config.debug == 1) {
							console.log(response.data);
						}

						this.setactiveproperty('task', 'refresh');
						this.setactiveproperty('taskitemtype', this.$store.state.active.item.type);
						this.setactiveproperty('taskitemid', this.$store.state.active.item.id);

						this.setactiveproperty('panel', null);
					}
				})
				.catch((error) => {
					this.errorMessage = error.message;
					console.error('There was an error!', error);
				});
		},

		bookmarkitem() {
			if (this.$store.state.config.debug == 1) {
				console.log('bookmark item');
			}

			var post = new Object();
			post.task = 'bookmark';
			post.itemid = this.$store.state.active.item.id;
			post.itemtype = this.$store.state.active.item.type;
			post.hdaccesstoken = this.$store.state.user.hdaccesstoken;

			// console.log(post);

			axios.post(this.$store.state.config.apiserver + 'interactions/panels/ItemMenu.php', post)
				.then((response) => {
					if (this.$store.state.config.debug == 1) {
						if (this.$store.state.config.debug == 1) {
							console.log(response.data);
						}
					}

					this.setactiveproperty('task', 'refresh');
					this.setactiveproperty('taskitemtype', this.$store.state.active.item.type);
					this.setactiveproperty('taskitemid', this.$store.state.active.item.id);

					this.setactiveproperty('panel', null);
				})
				.catch((error) => {
					this.errorMessage = error.message;
					console.error('There was an error!', error);
				});
		},
		deletepost() {
			if (this.$store.state.config.debug == 1) {
				console.log('deletepost');
			}

			var post = new Object();
			post.task = 'deletepost';
			post.postid = this.$store.state.active.item.id;
			post.hdaccesstoken = this.$cookie.get('hockeydo');

			// console.log(post);

			axios.post(this.$store.state.config.apiserver + 'interactions/panels/ItemMenu.php', post)
				.then((response) => {
					if (this.$store.state.config.debug == 1) {
						console.log(response.data);
					}

					this.setactiveproperty('task', 'delete');
					this.setactiveproperty('taskitemtype', this.$store.state.active.item.type);
					this.setactiveproperty('taskitemid', this.$store.state.active.item.id);

					this.$store.dispatch('fetchpostids');

					this.setactiveproperty('panel', '');
				})
				.catch((error) => {
					this.errorMessage = error.message;
					console.error('There was an error!', error);
				});
		},
	},
	computed: {
		bookmarkeditem() {
			return this.$store.state.active.item.bookmarkeditem;
		},
		bookmarkeduser() {
			return this.$store.state.active.item.bookmarkeduser;
		},
		itemuser() {
			return this.$store.state.active.item.userFirstname + '  ' + this.$store.state.active.item.userLastname;
		},
	},
	watch: {},
};
</script>
