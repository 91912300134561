<template>
	<transition name="slideup">
		<div v-if="this.$store.state.active.toppanel == panelname" class="panel">
			<div v-if="this.$store.state.config.debug == 1" class="debug">Panel | {{ panelname }}</div>

			<div class="row panel_header middle">
				<div class="col col-10 middle">
					<h1>{{ translate.USERPROFILE_OPMIJNCLUBBENIKOOK }}</h1>
				</div>
				<div class="col-2 middle-right">
					<a v-on:click="setactiveproperty('toppanel', '')" class="bi bi-x tekstblauw floatright"></a>
				</div>
			</div>

			<div class="panel_content">
				<div v-for="userfunction in this.$store.state.staticdata.userfunctions" :key="userfunction.id">
					<span v-if="userfunctionids">
						<span v-if="userfunctionids.includes(userfunction.id)">
							<a v-on:click="setuserfunction(userfunction)" class="button mb10">{{ userfunction[this.$store.state.active.languagewebsite] }}</a>
						</span>

						<span v-else>
							<a v-on:click="setuserfunction(userfunction)" class="button ghost mb10">{{ userfunction[this.$store.state.active.languagewebsite] }}</a>
						</span>
					</span>

					<span v-else>
						<a v-on:click="setuserfunction(userfunction)" class="button ghost mb10">{{ userfunction[this.$store.state.active.languagewebsite] }}</a>
					</span>
				</div>

				<a v-on:click="setactiveproperty('toppanel', '')" class="button floatright">{{ translate.SAVE }}</a>
			</div>
		</div>
	</transition>
</template>

<script>
import axios from 'axios';

export default {
	data() {
		return {
			panelname: 'UserProfileFunctions',
		};
	},

	methods: {
		setuserfunction(userfunction) {
			if (this.$store.state.user.userfunctionids) {
				var functionsids = this.$store.state.user.userfunctionids.split(',');

				if (functionsids.includes(userfunction.id)) {
					functionsids = functionsids.filter(function (item) {
						return item !== userfunction.id;
					});
				} else {
					functionsids.push(userfunction.id);
				}
			} else {
				functionsids = userfunction.id;
			}

			if (this.$store.state.config.debug == 1) {
				console.log('setuserfunctions');
			}

			var post = new Object();
			post.task = 'setuserfunctions';
			post.userfunctionids = functionsids.toString();
			post.hdaccesstoken = this.$cookie.get('hockeydo');

			//console.log(post);

			axios.post(this.$store.state.config.apiserver + 'users/panels/UserProfile.php', post)
				.then((response) => {
					if (this.$store.state.config.debug == 1) {
						console.log(response.data);
					}
					this.$store.dispatch('fetchuser');
				})
				.catch((error) => {
					this.errorMessage = error.message;
					console.error('There was an error!', error);
				});
		},
	},

	computed: {
		userfunctionids() {
			return this.$store.state.user.userfunctionids;
		},
	},
};
</script>

<style scoped></style>
