import {createStore} from 'vuex';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export default createStore({
	state: {
		config: {
			apiserver: 'https://api.hockeydo.com/api/',
			logosurl: 'https://www.hockeydo.com/logos/',
			requireditems: ['timelinerules', 'postcats', 'locations', 'straffen', 'languages', 'regions', 'teams', 'manual', 'overtredingen', 'competitions', 'continentscountriesregions', 'translations', 'clubs', 'kaarten', 'useractivities', 'userfunctions', 'countries', 'continents'],
			debug: 1,
		},

		active: {
			postsloading: false,
			siteloaded: false,
			rankingsShowReferees: true,
			rankingsShowUsers: true,
			loadeditems: [],
		},
		current: {},
		counts: {},
		games: {},
		home: {},
		route: {},
		posts: {
			postids: [],
			postbatch: 0,
			postloading: false,
		},
		maps: {},
		staticdata: {},
		task: {},
		user: {},
		we: {},
	},

	getters,
	mutations,
	actions,
});
