import axios from 'axios';

import store from '../store';

export default {
	homefetchchatids({commit}) {
		if (store.state.config.debug == 1) {
			console.log('home fetch chatids');
		}

		var post = new Object();
		post.task = 'fetchchatids';

		if (store.state.user) {
			post.hdaccesstoken = store.state.user.hdaccesstoken;
		}

		//console.log(post);

		axios.post(store.state.config.apiserver + 'home-actions.php', post)
			.then((response) => {
				var items = response.data;

				// console.log(items);

				if (items) {
					// Sorteren op prio

					items.sort(function (a, b) {
						return parseFloat(b.prio) - parseFloat(a.prio);
					});

					// De hoogste prios houden op basis van de ratio;

					var ratio = store.state.home.rules.find((obj) => obj.name === 'chat').ratio;

					// ratio = 2;

					if (items && items.slice) {
						items = items.slice(0, ratio);
					}

					// De prios die overblijven indelen tussen 0 en 1000;

					const minRating = Math.min(...items.map((obj) => obj.prio));
					const maxRating = Math.max(...items.map((obj) => obj.prio));
					const ratingRange = maxRating - minRating;

					items.forEach((obj) => {
						obj.oldprio = obj.prio;
						const scaledRating = (obj.prio - minRating) / ratingRange;
						obj.prio = Math.round(scaledRating * 1000);
					});

					const payload = new Object();
					payload.property = 'chatids';
					payload.value = items;

					commit('sethomeproperty', payload);
				}
			})
			.catch((error) => {
				this.errorMessage = error.message;
				console.error('There was an error!', error);
			});
	},

	homefetchgames({commit}) {
		if (store.state.config.debug == 1) {
			console.log('home fetch game');
		}

		var post = new Object();
		post.task = 'fetchgame';

		if (store.state.user) {
			post.hdaccesstoken = store.state.user.hdaccesstoken;
		}

		// console.log(post);

		axios.post(store.state.config.apiserver + 'home-actions.php', post)
			.then((response) => {
				const debug = 0;

				if (debug == 1) {
					console.log(response.data);
				} else {
					const payload = new Object();
					payload.property = 'gameids';
					payload.value = response.data;

					commit('sethomeproperty', payload);
				}
			})
			.catch((error) => {
				this.errorMessage = error.message;
				console.error('There was an error!', error);
			});
	},

	homefetchfeedids({commit}) {
		if (store.state.config.debug == 1) {
			console.log('fetch feedids');
		}

		var post = new Object();
		post.task = 'getfeedids';

		if (store.state.user) {
			post.hdaccesstoken = store.state.user.hdaccesstoken;
		}

		// console.log(post);

		axios.post(store.state.config.apiserver + 'home-actions.php', post)
			.then((response) => {
				var items = response.data;

				// console.log(items);

				// Sorteren op prio

				items.sort(function (a, b) {
					return parseFloat(b.prio) - parseFloat(a.prio);
				});

				// De hoogste prios houden op basis van de ratio;

				const ratio = store.state.home.rules.find((obj) => obj.name === 'feeditem').ratio;

				if (items && items.slice) {
					items = items.slice(0, ratio);
				}

				// De prios die overblijven indelen tussen 0 en 1000;

				const minRating = Math.min(...items.map((obj) => obj.prio));
				const maxRating = Math.max(...items.map((obj) => obj.prio));
				const ratingRange = maxRating - minRating;

				items.forEach((obj) => {
					obj.oldprio = obj.prio;
					const scaledRating = (obj.prio - minRating) / ratingRange;
					obj.prio = Math.round(scaledRating * 1000);
				});

				const payload = new Object();
				payload.property = 'feedsids';
				payload.value = items;

				commit('sethomeproperty', payload);
			})
			.catch((error) => {
				this.errorMessage = error.message;
				console.error('There was an error!', error);
			});
	},

	homefetchpostids({commit}) {
		if (store.state.config.debug == 1) {
			console.log('home fetch postids');
		}

		var post = new Object();
		post.task = 'getpostids';

		if (store.state.user) {
			post.hdaccesstoken = store.state.user.hdaccesstoken;
		}

		// console.log('bob');

		axios.post(store.state.config.apiserver + 'home-actions.php', post)
			.then((response) => {
				var items = response.data;

				// console.log(items);

				// Sorteren op prio

				items.sort(function (a, b) {
					return parseFloat(b.prio) - parseFloat(a.prio);
				});

				// De hoogste prios houden op basis van de ratio;

				const ratio = store.state.home.rules.find((obj) => obj.name === 'post').ratio;

				if (items && items.slice) {
					items = items.slice(0, ratio);
				}

				// De prios die overblijven indelen tussen 0 en 1000;

				const minRating = Math.min(...items.map((obj) => obj.prio));
				const maxRating = Math.max(...items.map((obj) => obj.prio));
				const ratingRange = maxRating - minRating;

				items.forEach((obj) => {
					obj.oldprio = obj.prio;
					const scaledRating = (obj.prio - minRating) / ratingRange;
					obj.prio = Math.round(scaledRating * 1000);
				});

				const payload = new Object();
				payload.property = 'postids';
				payload.value = response.data;

				commit('sethomeproperty', payload);
			})
			.catch((error) => {
				this.errorMessage = error.message;
				console.error('There was an error!', error);
			});
	},
};
