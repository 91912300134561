<template>
	<transition name="slideup">
		<div v-if="this.$store.state.active.panel == panelname" class="panel autoheight">
			<div v-if="this.$store.state.config.debug == 1" class="debug">Panel | {{ panelname }}</div>
			<div class="row panel_header middle">
				<div class="col col-6 middle">
					<h1>{{ translate.GAMESHEADER_TITLE }}</h1>
				</div>
				<div class="col-6 middle-right">
					<a v-on:click="setactiveproperty('panel', '')" class="bi bi-x tekstblauw floatright"></a>
				</div>
			</div>
			<div class="panel_content">
				<table width="100%">
					<tr>
						<td width="33%" style="vertical-align: top">
							<div v-for="competition in competitions" v-bind:key="competition.id">
								<span v-if="current.competition.id == competition.id">
									<router-link v-on:click="setactiveproperty('panel', '')" :to="{name: 'Games', params: {competition: competition.slug, game: 'game-' + current.game.game, video: 'video-1', mode: current.mode}}">
										<a class="button mb10">
											{{ competition['title_' + languagewebsite] }}

											<span class="countbullit" v-if="getpostcountbycompetition(competition) > 0">{{ getpostcountbycompetition(competition) }}</span>
										</a>
									</router-link>
								</span>
								<span v-else>
									<router-link v-on:click="setactiveproperty('panel', '')" :to="{name: 'Games', params: {competition: competition.slug, game: 'game-' + current.game.game, video: 'video-1', mode: current.mode}}">
										<a class="button ghost mb10">
											{{ competition['title_' + languagewebsite] }}

											<span class="countbullit" v-if="getpostcountbycompetition(competition) > 0">{{ getpostcountbycompetition(competition) }}</span>
										</a>
									</router-link>
								</span>
							</div>
						</td>

						<td width="33%" style="vertical-align: top">
							<div v-if="games">
								<div v-for="game in games" v-bind:key="game">
									<router-link v-on:click="setactiveproperty('panel', '')" :to="{name: 'Games', params: {competition: current.competition.slug, game: 'game-' + game.game, video: 'video-1', mode: current.mode}}">
										<a :class="['button mb10', current.game.id != game.id ? 'ghost' : '']">
											{{ translate.GAMESHEADER_WEEK }} {{ game.game }}

											<span class="countbullit" v-if="getpostcountbygame(game) > 0">{{ getpostcountbygame(game) }}</span>

											<div class="status" v-if="game.finalstatus == 'waittoplay'">
												{{ translate.GAMEMENU_PLAYIN }} {{ game.daystillplay }}
												<span v-if="game.daystillplay == 1">{{ translate.GAMEMENU_DAG }}</span>
												<span v-if="game.daystillplay == 7">{{ translate.GAMEMENU_DAGEN }}</span>
											</div>

											<div class="status" v-if="game.finalstatus == 'play'">{{ translate.GAMEMENU_PLAY }}</div>

											<div class="status" v-if="game.finalstatus == 'waittochat'">
												{{ translate.GAMEMENU_CHATIN }} {{ game.daystillchat }}
												<span v-if="game.daystillchat == 1">{{ translate.GAMEMENU_DAG }}</span>
												<span v-if="game.daystillchat > 1">{{ translate.GAMEMENU_DAGEN }}</span>
											</div>

											<div class="status" v-if="game.finalstatus == 'chat'">{{ translate.GAMEMENU_CHAT }}</div>
										</a>
									</router-link>
								</div>
							</div>
						</td>

						<td width="33%" style="vertical-align: top">
							<div v-if="videos">
								<div v-for="(video, index) in videos" v-bind:key="index">
									<router-link v-on:click="setactiveproperty('panel', '')" :to="{name: 'Games', params: {competition: current.competition.slug, game: 'game-' + current.game.game, video: 'video-' + video.nummer, mode: current.mode}}">
										<a :class="['button mb10', current.video.id != video.id ? 'ghost' : '']">
											Video {{ video.nummer }}
											<i v-if="getvideoplayed(video)" class="bi bi-check-circle-fill"></i>
											<span class="countbullit" v-if="getpostcountbyvideo(video) > 0">{{ getpostcountbyvideo(video) }}</span>
										</a>
									</router-link>
								</div>
							</div>
						</td>
					</tr>
				</table>
			</div>
		</div>
	</transition>
</template>

<script>
// import axios from "axios";
import {mapState} from 'vuex';

export default {
	data() {
		return {
			panelname: 'GamesMenu',
		};
	},

	methods: {
		getvideoplayed(video) {
			return this.current.game['videoplayed_' + video.nummer];
		},

		getpostcountbyvideo(video) {
			const unreadpostscount = this.$store.state.counts?.unreadpostsbyvideo;
			if (!unreadpostscount) return null;

			const post = unreadpostscount.find((item) => item.video_id === video.id);
			return post?.post_count || null;
		},

		getpostcountbygame(game) {
			const unreadpostscount = this.$store.state.counts?.unreadpostsbyvideo;
			if (!unreadpostscount) return null;

			const videoIds = Object.keys(game)
				.filter((key) => key.startsWith('video_'))
				.map((key) => game[key]?.id)
				.filter((id) => id && id !== '0');

			const totalPosts = unreadpostscount.filter((item) => videoIds.includes(item.video_id)).reduce((sum, item) => sum + parseInt(item.post_count), 0);

			return totalPosts > 0 ? totalPosts : null;
		},

		getpostcountbycompetition(competition) {
			// console.log(competition);

			const videoIds = competition.games.flatMap((game) =>
				Object.keys(game)
					.filter((key) => key.startsWith('video_'))
					.map((key) => game[key]?.id)
					.filter((id) => id && id !== '0')
			);

			// console.log(videoIds);

			const unreadpostscount = this.$store.state.counts?.unreadpostsbyvideo;
			if (!unreadpostscount) return null;

			const totalPosts = unreadpostscount.filter((item) => videoIds.includes(item.video_id)).reduce((sum, item) => sum + parseInt(item.post_count), 0);

			return totalPosts > 0 ? totalPosts : null;
		},
	},

	computed: {
		...mapState(['current', 'competitions']),

		games() {
			var games = this.current.competition.games;

			games.sort((a, b) => a.ordering - b.ordering);

			return games;
		},
		gamestatus() {
			return this.current.game.status;
		},

		videos() {
			var game = this.current.game;

			const videoFields = ['video_1', 'video_2', 'video_3', 'video_4', 'video_5', 'video_6', 'video_7', 'video_8'];
			const filledVideos = videoFields
				.map((field, index) => {
					if (typeof game[field] === 'object' && game[field] !== null && Object.keys(game[field]).length > 0) {
						return {
							...game[field],
							nummer: index + 1,
						};
					}
					return null;
				})
				.filter((video) => video !== null);

			return filledVideos;
		},
	},
};
</script>

<style scoped>
.button {
	width: 90%;
	justify-content: left;
}

.button .status {
	font-size: 12px;
}

.countbullit {
	position: absolute;
	right: -5px;
	top: -5px;
}

.bi-chat-square-dots {
	font-size: 18px;
	position: absolute;
	top: 10px;
	right: 15px;
}

.bi-controller {
	font-size: 22px;
	position: absolute;
	top: 8px;
	right: 15px;
}

.bi-check-circle-fill {
	font-size: 16px;
	position: absolute;
	top: 10px;
	right: 15px;
}

.bi-clock-history {
	font-size: 16px;
	position: absolute;
	top: 10px;
	right: 15px;
}

.ghost .bi {
	color: var(-blauw);
}
</style>
