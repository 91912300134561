<template>
	<div if="club" class="view">
		<div id="map" ref="infomap"></div>

		<div class="row mb15 d-none d-lg-flex">
			<div class="col-4">
				<div v-if="loggedin">
					<div v-if="isownclub">
						<a v-on:click="setactiveproperty('panel', 'CorrectClub')" class="tekstgroen">{{ translate.CLUBINFO_CORRECT }}</a>
					</div>

					<div v-if="userhasnoclub" v-on:click="setactiveproperty('toppanel', 'SetUserClubConfirmation')" class="button rood">{{ translate.SETOWNCLUB_BUTTON }}</div>
				</div>
			</div>

			<div class="col-4"></div>

			<div class="col-4 middle-right">
				<div v-if="loggedin">
					<a v-if="bookmarked" v-on:click="bookmarkclub()" class="tekstgroen">
						<i class="bi bi-bookmark-fill klein tekstgroen"></i>
						{{ translate.CLUBINFO_CLUBOPSLAAN }}
					</a>
					<a v-if="!bookmarked" v-on:click="bookmarkclub()" class="tekstgroen">
						<i class="bi bi-bookmark klein tekstgroen"></i>
						{{ translate.CLUBINFO_CLUBOPSLAAN }}
					</a>
				</div>
			</div>
		</div>

		<div v-if="club" class="horizontalscroll">
			<div class="item">
				<h2>{{ club.name_long }}</h2>
				{{ club.address }}
				<br />
				{{ club.place }}
				<br />
				{{ club.region }}
				<br />
				<br />

				<a v-on:click="showonmap(club.geoposition)" class="tekstblauw mb5">{{ translate.CLUBINFO_TOONOPKAART }}</a>
				<a :href="showongooglemaps(club.geoposition)" target="_BLANK" class="tekstblauw">{{ translate.CLUBINFO_ROUTE }}</a>
			</div>

			<div v-if="club.indoor_1_name" class="item">
				<h2>{{ translate.CLUBINFO_ZAAL }} 1</h2>
				{{ club.indoor_1_name }}
				<br />
				{{ club.indoor_1_address }}
				<br />
				{{ club.indoor_1_place }}
				<br />
				<br />
				<a v-on:click="showonmap(club.indoor_1_geoposition)" class="tekstblauw mb5">{{ translate.CLUBINFO_TOONOPKAART }}</a>
				<a :href="showongooglemaps(club.indoor_1_geoposition)" target="_BLANK" class="tekstblauw">{{ translate.CLUBINFO_ROUTE }}</a>
			</div>

			<div v-if="club.indoor_2_name" class="item">
				<h2>{{ translate.CLUBINFO_ZAAL }} 2</h2>
				{{ club.indoor_2_name }}
				<br />
				{{ club.indoor_2_address }}
				<br />
				{{ club.indoor_2_place }}
				<br />
				<br />
				<a v-on:click="showonmap(club.indoor_2_geoposition)" class="tekstblauw mb5">{{ translate.CLUBINFO_TOONOPKAART }}</a>
				<a :href="showongooglemaps(club.indoor_2_geoposition)" target="_BLANK" class="tekstblauw">{{ translate.CLUBINFO_ROUTE }}</a>
			</div>

			<div v-if="club.indoor_3_name" class="item">
				<h2>{{ translate.CLUBINFO_ZAAL }} 3</h2>
				{{ club.indoor_3_name }}
				<br />
				{{ club.indoor_3_address }}
				<br />
				{{ club.indoor_3_place }}
				<br />
				<br />
				<a v-on:click="showonmap(club.indoor_3_geoposition)" class="tekstblauw mb5">{{ translate.CLUBINFO_TOONOPKAART }}</a>
				<a :href="showongooglemaps(club.indoor_3_geoposition)" target="_BLANK" class="tekstblauw">{{ translate.CLUBINFO_ROUTE }}</a>
			</div>

			<div v-if="club.indoor_4_name" class="item">
				<h2>{{ translate.CLUBINFO_ZAAL }} 4</h2>
				{{ club.indoor_4_name }}
				<br />
				{{ club.indoor_4_address }}
				<br />
				{{ club.indoor_4_place }}
				<br />
				<br />
				<a v-on:click="showonmap(club.indoor_4_geoposition)" class="tekstblauw mb5">{{ translate.CLUBINFO_TOONOPKAART }}</a>
				<a :href="showongooglemaps(club.indoor_4_geoposition)" target="_BLANK" class="tekstblauw">{{ translate.CLUBINFO_ROUTE }}</a>
			</div>

			<div v-if="club.outdoor_2_name" class="item">
				<h2>{{ translate.CLUBINFO_VELD }} 2</h2>
				<div class="dik">{{ club.outdoor_2_name }}</div>
				{{ club.outdoor_2_address }}
				<br />
				{{ club.outdoor_2_place }}
				<br />
				<br />

				<a v-on:click="showonmap(club.outdoor_2_geoposition)" class="tekstblauw mb5">{{ translate.CLUBINFO_TOONOPKAART }}</a>
				<a :href="showongooglemaps(club.outdoor_2_geoposition)" target="_BLANK" class="tekstblauw">{{ translate.CLUBINFO_ROUTE }}</a>
			</div>

			<div v-if="club.outdoor_3_name" class="item">
				<h2>{{ translate.CLUBINFO_VELD }} 3</h2>
				<div class="dik">{{ club.outdoor_3_name }}</div>
				{{ club.outdoor_3_address }}
				<br />
				{{ club.outdoor_3_place }}
				<br />
				<br />
				<a v-on:click="showonmap(club.outdoor_3_geoposition)" class="tekstblauw mb5">{{ translate.CLUBINFO_TOONOPKAART }}</a>
				<a :href="showongooglemaps(club.outdoor_2_geoposition)" target="_BLANK" class="tekstblauw">{{ translate.CLUBINFO_ROUTE }}</a>
			</div>

			<div v-if="club.outdoor_4_name" class="item">
				<h2>{{ translate.CLUBINFO_VELD }} 4</h2>
				<div class="dik">{{ club.outdoor_4_name }}</div>
				{{ club.outdoor_4_address }}
				<br />
				{{ club.outdoor_4_place }}
				<br />
				<br />
				<a v-on:click="showonmap(club.outdoor_4_geoposition)" class="tekstblauw mb5">{{ translate.CLUBINFO_TOONOPKAART }}</a>
				<a :href="showongooglemaps(club.outdoor_2_geoposition)" target="_BLANK" class="tekstblauw">{{ translate.CLUBINFO_ROUTE }}</a>
			</div>
		</div>

		<div class="row mb15 d-md-none">
			<div class="col-4">
				<a v-on:click="opencorrectclubpanel()" class="tekstgroen">{{ translate.CLUBINFO_CORRECT }}</a>
			</div>

			<div class="col-4"></div>

			<div class="col-4 middle-right">
				<a v-if="bookmarked" v-on:click="bookmarkclub()" class="tekstgroen">
					<i class="bi bi-bookmark-fill klein tekstgroen"></i>
					{{ translate.CLUBINFO_CLUBOPSLAAN }}
				</a>
				<a v-if="!bookmarked" v-on:click="bookmarkclub()" class="tekstgroen">
					<i class="bi bi-bookmark klein tekstgroen"></i>
					{{ translate.CLUBINFO_CLUBOPSLAAN }}
				</a>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios';

export default {
	data() {
		return {};
	},

	methods: {
		showongooglemaps(geoposition) {
			var url = 'https://www.google.com/maps/dir/?api=1&destination=' + geoposition;

			return url;
		},

		setAltImg(event) {
			event.target.src = this.$store.state.config.logosurl + 'nologo.png';
		},

		bookmarkclub() {
			if (this.$store.state.config.debug == 1) {
				console.log('bookmarkclub');
			}

			var post = new Object();
			post.task = 'bookmarkclub';
			post.clubid = this.$store.state.current.club.id;
			post.hdaccesstoken = this.$cookie.get('hockeydo');

			//console.log(post);

			axios.post(this.$store.state.config.apiserver + 'clubs/views/clubinfo.php', post)
				.then((response) => {
					if (this.$store.state.config.debug == 1) {
						if (this.$store.state.config.debug == 1) {
							console.log(response.data);
						}

						this.setactiveproperty('task', 'refresh');
						this.setactiveproperty('taskitemtype', 'club');
						this.setactiveproperty('taskitemid', this.$store.state.current.club.id);
					}
				})
				.catch((error) => {
					this.errorMessage = error.message;
					console.error('There was an error!', error);
				});
		},
		initMap() {
			if (this.$store.state.current.club) {
				var L = window.L;

				if (this.$store.state.config.debug == 1) {
					console.log('initMap');
				}

				if (this.map) {
					this.map.remove();
				}

				this.map = new L.map(this.$refs.infomap).setView([0, 0], 1);

				this.tileLayer = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
					maxZoom: 17,
					attribution: '© <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
				});

				this.tileLayer.addTo(this.map);
			}

			this.showclubonmap();
		},
		showonmap(geoposition) {
			var L = window.L;

			if (this.$store.state.config.debug == 1) {
				console.log('showonmap');
			}
			if (this.map) {
				this.map.remove();
			}

			this.map = new L.map(this.$refs.infomap, {
				dragging: false,
				touchZoom: false,
				scrollWheelZoom: false,
				doubleClickZoom: false,
				zoomControl: false,
			}).setView([0, 0], 1);

			this.tileLayer = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
				maxZoom: 17,
				attribution: '© <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
			});
			this.tileLayer.addTo(this.map);

			geoposition = geoposition.split(',');

			var myIcon = L.icon({
				iconUrl: 'https://www.hockeydo.com/images/marker.png',
				iconSize: [40, 40],
				iconAnchor: [20, 40],
				popupAnchor: [-3, -76],
			});

			if (typeof geoposition !== 'undefined') {
				L.marker(geoposition, {icon: myIcon}).addTo(this.map);

				this.map.setView(geoposition, 14);
			}
		},
		showclubonmap() {
			var L = window.L;
			if (this.$store.state.config.debug == 1) {
				console.log('showclubonmap');
			}
			if (this.map) {
				this.map.remove();
			}
			this.map = new L.map(this.$refs.infomap, {
				dragging: false,
				touchZoom: false,
				scrollWheelZoom: false,
				doubleClickZoom: false,
				zoomControl: false,
			}).setView([0, 0], 1);
			this.tileLayer = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
				maxZoom: 17,
				attribution: '© <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
			});
			this.tileLayer.addTo(this.map);

			if (this.$store.state.current.club.geoposition) {
				var geoposition = this.$store.state.current.club.geoposition.split(',');
				var myIcon = L.icon({
					iconUrl: 'https://www.hockeydo.com/images/marker.png',
					iconSize: [40, 40],
					iconAnchor: [20, 40],
					popupAnchor: [-3, -76],
				});
				L.marker(geoposition, {icon: myIcon}).addTo(this.map);
				this.map.setView(geoposition, 14);
			}
		},
	},

	computed: {
		userhasnoclub() {
			if (this.$store.state.user.clubid < 1) {
				return true;
			} else {
				return false;
			}
		},

		club() {
			return this.$store.state.current.club;
		},
		bookmarked() {
			if (this.$store.state.current.club) {
				return this.$store.state.current.club.bookmarked;
			} else {
				return false;
			}
		},

		isownclub() {
			if (this.$store.state.current.club) {
				if (this.$store.state.user.clubid == this.$store.state.current.club.id) {
					return true;
				} else {
					return false;
				}
			} else {
				return false;
			}
		},
	},
	watch: {
		club() {
			this.showclubonmap();
		},
	},

	mounted() {
		this.initMap();

		setTimeout(() => {
			this.initMap();
		}, 300);

		// Truuk om de methods buiten de component aan te kunnen spreken. In main.js: window.app = app;
		// window.app.MapComponent = this;
	},
};
</script>

<style scoped>
#map {
	position: static;
	z-index: 0;
	height: 300px;
	margin-bottom: 15px;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	margin-right: -15px;
	margin-left: -15px;
	margin-top: -15px;
}
</style>
