import axios from 'axios';
import {ref} from 'vue';

export const isLoading = ref(false);
export const isOffline = ref(false);
let activeRequests = 0;
let loadingTimeout = null;

// Check netwerk status
window.addEventListener('online', () => (isOffline.value = false));
window.addEventListener('offline', () => (isOffline.value = true));

axios.interceptors.request.use(
	(config) => {
		activeRequests++;

		if (!loadingTimeout) {
			loadingTimeout = setTimeout(() => {
				if (activeRequests > 0) {
					isLoading.value = true;
				}
			}, 500);
		}

		return config;
	},
	(error) => {
		activeRequests--;
		isLoading.value = false;
		return Promise.reject(error);
	}
);

axios.interceptors.response.use(
	(response) => {
		activeRequests--;
		if (activeRequests === 0) {
			clearTimeout(loadingTimeout);
			loadingTimeout = null;
			isLoading.value = false;
		}
		return response;
	},
	(error) => {
		activeRequests--;
		if (activeRequests === 0) {
			clearTimeout(loadingTimeout);
			loadingTimeout = null;
			isLoading.value = false;
		}

		// Check voor netwerk errors
		if (!error.response && error.message === 'Network Error') {
			isOffline.value = true;
		}

		return Promise.reject(error);
	}
);
