<template>
	<transition name="slideup">
		<div v-if="this.$store.state.active.toppanel == panelname" class="panel">
			<div v-if="this.$store.state.config.debug == 1" class="debug">Toppanel | {{ panelname }}</div>

			<div class="row panel_header middle">
				<div class="col col-6 middle">
					<h1>{{ translate.FILTER_KIESCLUB }}</h1>
				</div>
				<div class="col-6 middle-right">
					<a v-on:click="setactiveproperty('toppanel', '')" class="bi bi-x tekstblauw floatright"></a>
				</div>
			</div>

			<div class="panel_content">
				<div v-for="club in clubs" :key="club.id">
					<a class="ghost button mb10" v-on:click="setclub(club)">{{ club.club_namelong }}</a>
				</div>

				<div v-if="loggedin">
					<a class="button" v-on:click="openpanel('AddClub')">{{ translate.SEARCH_ADDCLUB }}</a>
				</div>

				<div v-if="!loggedin">
					<a class="button" v-on:click="openpanel('Login')">{{ translate.SEARCH_ADDCLUB }}</a>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import {mapState} from 'vuex';

export default {
	data() {
		return {
			panelname: 'FilterAreaAreaClubs',
		};
	},

	components: {},
	methods: {
		setclub(club) {
			this.setmapsproperty('club', club);

			this.setactiveproperty('toppanel', '');
			this.setactiveproperty('searchstring', '');
		},
		openpanel(panel) {
			this.setactiveproperty('toppanel', '');
			this.setactiveproperty('panel', panel);
			this.setactiveproperty('searchstring', '');
		},
	},

	computed: {
		...mapState(['staticdata', 'active', 'maps']),
		clubs() {
			const regionId = this.maps.region.id;
			const clubs = this.staticdata.clubs
				.filter((club) => club.region_id === regionId)
				.sort((a, b) => {
					const nameA = a.club_namelong.toLowerCase();
					const nameB = b.club_namelong.toLowerCase();

					if (nameA < nameB) return -1;
					if (nameA > nameB) return 1;
					return 0;
				});

			return clubs;
		},
	},
};
</script>

<style scoped></style>
