export default {
	setgame(state, game) {
		state.game = game;
	},

	setcompetition(state, competition) {
		state.games.competition = competition;
	},
	setgamesproperty(state, payload) {
		state.games[payload.property] = payload.value;
	},

	SET_RANKINGS(state, rankings) {
		state.rankings = rankings;
	},

	// 2024 SEPTEMBER
	setCurrentCompetition(state, data) {
		state.current.competition = data;
	},
	setCurrentGame(state, data) {
		state.current.game = data;
	},
	setCurrentVideo(state, data) {
		state.current.video = data;
	},
	setCurrentMode(state, data) {
		state.current.mode = data;
	},
	setUserGame(state, data) {
		state.usergame = data;
	},

	setCurrentGameProperty(state, payload) {
		state.current.game[payload.property] = payload.value;
	},
};
