<template>
	<transition name="slideup">
		<div v-if="this.$store.state.active.toppanel == panelname" class="panel">
			<div v-if="this.$store.state.config.debug == 1" class="debug">Toppanel | {{ panelname }}</div>

			<div class="row panel_header middle">
				<div class="col col-6 middle">
					<h1>{{ translate.FILTER_KIESCONTINENT }}</h1>
				</div>
				<div class="col-6 middle-right">
					<a v-on:click="setactiveproperty('toppanel', '')" class="bi bi-x tekstblauw floatright"></a>
				</div>
			</div>

			<div class="panel_content">
				<a class="ghost button mb10" v-on:click="setcontinent('')">{{ translate.WORLD }}</a>

				<div v-for="continent in continents" :key="continent.id">
					<a class="ghost button mb10" v-on:click="setcontinent(continent)">{{ continent[active.languagewebsite] }}</a>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import {mapState} from 'vuex';

export default {
	data() {
		return {
			panelname: 'FilterAreaAreaContinents',
		};
	},

	components: {},
	methods: {
		setcontinent(continent) {
			if (continent) {
				// Continent

				const clubsInContinent = this.staticdata.clubs.filter((item) => item.continent_id === continent.id);

				this.setmapsproperty('clubs', clubsInContinent);

				this.setmapsproperty('continent', continent);

				this.setmapsproperty('country', null);
				this.setmapsproperty('region', null);
				this.setmapsproperty('club', null);

				this.setactiveproperty('toppanel', '');
			} else {
				// De wereld laten zien

				this.setmapsproperty('clubs', this.staticdata.clubs);

				this.setmapsproperty('continent', null);

				this.setmapsproperty('country', null);
				this.setmapsproperty('region', null);
				this.setmapsproperty('club', null);

				this.setactiveproperty('toppanel', '');
			}
		},
	},

	computed: {
		...mapState(['staticdata', 'active']),

		continents() {
			const uniqueContinents = this.staticdata.continents;

			// Sort continents based on English names (en field)
			return uniqueContinents.sort((a, b) => {
				// Convert to lowercase for case-insensitive sorting
				const nameA = a.en.toLowerCase();
				const nameB = b.en.toLowerCase();

				if (nameA < nameB) return -1;
				if (nameA > nameB) return 1;
				return 0;
			});
		},
	},
};
</script>

<style scoped></style>
