<template>
	<a v-on:click="showpost()">
		<div class="panel_section">
			<div class="postheader" :class="{oldmessage}">
				<div class="links">
					<div v-html="profilepicture"></div>
				</div>

				<div class="midden">
					<span class="dik tekstzwart">{{ notification.userFirstname }} {{ notification.userLastname }}</span>
					{{ typetext }}
					<div class="klein tekstgrijs">{{ time }} &bull; {{ notification.clubNameshort }} &bull; {{ notification.clubPlace }} &bull; {{ clubCountry }}</div>
					<div>
						{{ notification.posttext }}
						<br />
						<div class="commenttext">{{ notification.commenttext }}</div>
					</div>
				</div>
			</div>
		</div>
	</a>
</template>

<script>
export default {
	name: 'Notification',

	props: ['notification'],

	methods: {
		showpost() {
			this.$router.push({path: this.$route.path, query: {postid: this.notification.postid}});

			this.$store.dispatch('taskShowPanel', null);
		},
	},

	computed: {
		typetext() {
			return this.translate['ALERT_' + this.notification.type];
			//return 'bob';
		},

		profilepicture() {
			if (this.notification.userimagename) {
				return '<img class="profilepicture" src="https://www.hockeydo.com/users/' + this.notification.userimagename + '.png"/>';
			} else {
				return (
					'<div class="profilesymbol ' +
					this.notification.userFirstname.charAt(0).toLowerCase() +
					'">' +
					this.notification.userFirstname.charAt(0).toUpperCase() +
					this.notification.userLastname
						.split(' ')
						.map((word) => word.charAt(0))
						.join('')
						.toUpperCase() +
					'</div>'
				);
			}
		},

		time() {
			var now = (Date.now() / 1000) | 0;
			var created = this.notification.unixtime;
			var diff = now - created;

			var minutes = Math.floor(diff / 60);
			if (minutes < 61) {
				return minutes + ' ' + this.$store.state.translate.POSTPOST_MINUTEN;
			}

			var hours = Math.floor(minutes / 60);
			if (hours < 25) {
				if (hours == 1) {
					return hours + ' ' + this.$store.state.translate.POSTPOST_UUR;
				} else {
					return hours + ' ' + this.$store.state.translate.POSTPOST_UREN;
				}
			}

			var days = Math.floor(hours / 24);
			if (days < 31) {
				if (days == 1) {
					return days + ' ' + this.$store.state.translate.POSTPOST_DAG;
				} else {
					return days + ' ' + this.$store.state.translate.POSTPOST_DAGEN;
				}
			}

			var months = Math.floor(days / 30);
			if (months < 12) {
				if (months == 1) {
					return months + ' ' + this.$store.state.translate.POSTPOST_MAAND;
				} else {
					return months + ' ' + this.$store.state.translate.POSTPOST_MAANDEN;
				}
			}

			var years = Math.floor(months / 12);

			if (years == 1) {
				return years + ' ' + this.$store.state.translate.POSTPOST_JAAR;
			} else {
				return years + ' ' + this.$store.state.translate.POSTPOST_JAREN;
			}
		},
		clubCountry() {
			return this.notification['clubCountry' + this.$store.state.current.languageWebsite];
		},
		oldmessage() {
			var lastseenmessage = Math.round(new Date(this.$store.state.user.lastseenmessage).getTime() / 1000);
			var created = this.notification.unixtime;

			if (created > lastseenmessage) {
				return false;
			} else {
				return true;
			}
		},
	},
};
</script>

<style scoped>
.oldmessage {
	opacity: 0.4;
}

.commenttext {
	display: inline-block;
	background: #eee;
	padding: 5px 20px;
	border-radius: 15px;
	clear: both;
}
</style>
