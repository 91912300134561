<template>
	<div class="mobilefooter">
		<router-link :to="{name: 'Home'}">
			<div v-if="section == 'home'">
				<a class="item">
					<i class="bi bi-house-fill"></i>
					<div class="text current">{{ translate.MOBILEFOOTER_HOME }}</div>
				</a>
			</div>

			<div v-else>
				<a class="item">
					<i class="bi bi-house"></i>
					<div class="text">{{ translate.MOBILEFOOTER_HOME }}</div>
				</a>
			</div>
		</router-link>

		<router-link v-if="competitions" :to="{name: 'Games', params: {competition: latestCompetitionSlug, game: 'game-1', video: 'video-1', mode: 'game'}}">
			<div v-if="section == 'games'">
				<a class="item">
					<GameIconActive />
					<div class="text current">{{ translate.MENU_PLAY }}</div>
				</a>
			</div>

			<div v-else>
				<a class="item">
					<GameIcon />
					<div class="text">{{ translate.MENU_PLAY }}</div>
				</a>
			</div>
		</router-link>

		<router-link :to="{name: 'Posts', params: {continent: currentContinentSlug, country: currentCountrySlug, region: currentRegionSlug, club: currentClubSlug, scope: currentScope, sub: 'posts', subsub: 'all'}}">
			<div v-if="this.$store.state.active.section == 'posts'">
				<a class="item">
					<i class="bi bi-chat-square-text-fill"></i>
					<div class="text current">{{ translate.MOBILEFOOTER_POSTS }}</div>
				</a>
			</div>

			<div v-else>
				<a class="item">
					<i class="bi bi-chat-square-text"></i>
					<div class="text">{{ translate.MOBILEFOOTER_POSTS }}</div>
				</a>
			</div>
		</router-link>

		<!-- <div v-if="this.store.state.current.club">
			<router-link :to="{name: 'Clubs', params: {continent: currentContinentSlug, country: currentCountrySlug, region: currentRegionSlug, club: currentClubSlug, scope: 'clubinfo', sub: 'info', subsub: 'info'}}">
				<div v-if="section == 'clubs'">
					<a class="item">
						<img v-if="clubLogoUrl" class="clublogo" :src="clubLogoUrl" @error="setAltImg" />

						<div class="text current">{{ this.$store.state.user.club.name_short }}</div>
					</a>
				</div>

				<div v-else>
					<a class="item">
						<img v-if="clubLogoUrl" class="clublogo" :src="clubLogoUrl" @error="setAltImg" />
						<div class="text">{{ this.$store.state.user.club.name_short }}</div>
					</a>
				</div>
			</router-link>
		</div> -->

		<router-link :to="{name: 'We', params: {group: 'referees', view: 'info'}}">
			<div v-if="section == 'we'">
				<a class="item">
					<i class="bi bi-people-fill"></i>
					<div class="text current">{{ translate.MOBILEFOOTER_WE }}</div>
				</a>
			</div>

			<div v-else>
				<a class="item">
					<i class="bi bi-people"></i>
					<div class="text">{{ translate.MOBILEFOOTER_WE }}</div>
				</a>
			</div>
		</router-link>
	</div>
</template>

<script>
import {mapState} from 'vuex';

import GameIcon from '@/components/common/icons/GameIcon.vue';
import GameIconActive from '@/components/common/icons/GameIconActive.vue';

export default {
	name: 'Footer',

	components: {
		GameIcon,
		GameIconActive,
	},

	computed: {
		...mapState(['active', 'competitions']),

		section() {
			if (this.$store.state.active.section) {
				return this.$store.state.active.section;
			} else {
				return null;
			}
		},
		clubLogoUrl() {
			if (this.$store.state.current.club) {
				return this.$store.state.config.logosurl + this.$store.state.current.club.id + '.png';
			} else {
				return null;
			}
		},

		currentClubSlug() {
			if (this.$store.state.current.club) {
				return this.$store.state.current.club.slug;
			} else {
				if (this.$store.state.user && this.$store.state.user.club) {
					return this.$store.state.user.club.slug;
				} else {
					return '-';
				}
			}
		},
		currentRegionSlug() {
			if (this.$store.state.current.region) {
				return this.$store.state.current.region.slug;
			} else {
				if (this.$store.state.user && this.$store.state.user.club) {
					return this.$store.state.user.club.region_slug;
				} else {
					return '-';
				}
			}
		},
		currentCountrySlug() {
			if (this.$store.state.current.country) {
				return this.$store.state.current.country.slug;
			} else {
				if (this.$store.state.user && this.$store.state.user.club) {
					return this.$store.state.user.club.country_slug;
				} else {
					return '-';
				}
			}
		},
		currentContinentSlug() {
			if (this.$store.state.current.continent) {
				return this.$store.state.current.continent.slug;
			} else {
				if (this.$store.state.user && this.$store.state.user.club) {
					return this.$store.state.user.club.continent_slug;
				} else {
					return '-';
				}
			}
		},

		currentScope() {
			var scope = 'world';

			if (this.$store.state.current.continent) {
				scope = 'continent';
			}

			if (this.$store.state.current.country) {
				scope = 'country';
			}

			if (this.$store.state.current.region) {
				scope = 'region';
			}

			if (this.$store.state.current.club) {
				scope = 'club';
			}

			return scope;
		},

		latestCompetitionSlug() {
			var competitions = this.competitions;

			const highestOrderingCompetition = this.competitions.reduce((max, competition) => (competition.ordering > max.ordering ? competition : max), competitions[0]);
			return highestOrderingCompetition.slug;
		},
	},
};
</script>

<style scoped>
.mobilefooter {
	background: #fff;
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding: 0px 10px;
	-webkit-box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.05);
	box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.05);
}

.mobilefooter i {
	padding: 10px;
	color: var(--groen);
	font-size: 26px;
	padding: 10px 10px 3px 10px;
}

.icon.game {
	margin-top: 8px;
}

.clublogo {
	height: 40px;
	width: auto;
	padding: 10px 5px 0px 5px;
	margin-bottom: -7px;
}

.text {
	font-size: 11px;
	width: 100%;
	text-align: center;
	color: #3bb58e;
}

.text.current {
	color: #000;
	font-weight: 600;
}
</style>
