<template>
	<div class="view nl2br">
		<h1>{{ translate.WAITTOCHAT_KOP }}</h1>

		<Vue3Lottie :animationData="animationData" :height="200" :width="200" :loop="false" />

		{{ translate.WAITTOCHAT_TEXT }}
		<br />
		<br />
		{{ translate.WAIT_BEFOREDAYS }} {{ this.current.game.daystillchat }} {{ translate.WAIT_AFTERDAYS }}
	</div>
</template>

<script>
import {mapState} from 'vuex';
import {Vue3Lottie} from 'vue3-lottie';
import animationData from '@/assets/lotti/gameplayed_nl.json';

export default {
	data() {
		return {
			animationData,
		};
	},

	components: {
		Vue3Lottie,
	},
	computed: {
		...mapState(['current']),
	},
	watch: {},

	methods: {},
};
</script>

<style scoped>
.view {
	text-align: center;
	padding: 30px;
}
</style>
