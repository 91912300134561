<template>
	<GamesHeader />

	<div v-if="current.mode == 'game'">
		<Play v-if="current.game.finalstatus == 'play'" />
		<Chat v-if="current.game.finalstatus == 'chat'" />
		<WaitToPlay v-if="current.game.finalstatus == 'waittoplay'" />
		<WaitToChat v-if="current.game.finalstatus == 'waittochat'" />
	</div>

	<div v-if="current.mode == 'ranking'">
		<RankingsMenu />
		<Rankings />
	</div>
</template>

<script>
import GamesHeader from '@/components/games/GamesHeader.vue';
import Play from '@/components/games/views/Play.vue';
import Chat from '@/components/games/views/Chat.vue';
import WaitToPlay from '@/components/games/views/WaitToPlay.vue';
import WaitToChat from '@/components/games/views/WaitToChat.vue';
import Rankings from '@/components/games/views/Rankings.vue';
import RankingsMenu from '@/components/games/views/RankingsMenu.vue';

import {mapState} from 'vuex';

export default {
	data() {
		return {
			temp: null,
		};
	},
	components: {
		GamesHeader,
		Play,
		Chat,
		WaitToPlay,
		WaitToChat,
		Rankings,
		RankingsMenu,
	},

	computed: {
		...mapState(['current', 'competitions']),
	},
	watch: {},
	mounted() {},
};
</script>
