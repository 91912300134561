<template>
	<HomeManual />

	<div v-for="item in activeitems" :key="item.vueid">
		<!-- <div class="prio" >{{ item }}</div> -->

		<div v-if="item.type == 'play'">
			<PlayItem v-bind:itemid="item" />
		</div>

		<div v-if="item.type == 'chat'">
			<ChatItem v-bind:itemid="item" />
		</div>

		<div v-if="item.type == 'feeditem'">
			<FeedItem v-bind:itemid="item" />
		</div>

		<div v-if="item.type == 'post'">
			<Post v-bind:postid="item.id" />
		</div>
	</div>
</template>

<script>
import FeedItem from '@/components/home/HomeFeeditem.vue';
import Post from '@/components/posts/PostsPost.vue';
import PlayItem from '@/components/home/HomePlayItem.vue';
import ChatItem from '@/components/home/HomeChatItem.vue';
import HomeManual from '@/components/home/HomeManual.vue';

// import axios from "axios";

export default {
	data() {
		return {
			batch: 10,
		};
	},
	components: {
		FeedItem,
		PlayItem,
		ChatItem,
		Post,
		HomeManual,
	},
	methods: {
		infiniteScroll() {
			if (window.scrollY > document.body.offsetHeight - window.outerHeight - 200) {
				if (this.$route.name == 'Home') {
					this.batch = this.batch + 5;
					// console.log(this.activeitems.length);
				}
			}
		},
	},

	computed: {
		items() {
			var items = [];

			var feedids = this.$store.state.home.feedsids;
			var postids = this.$store.state.home.postids;
			var gameids = this.$store.state.home.gameids;
			var chatids = this.$store.state.home.chatids;

			var chats = 1; // chats van games laten zien
			var posts = 1; // posts laten zien
			var feeds = 1; // feeds laten zien
			var games = 1; // games laten zien

			if (items && postids) {
				if (posts == 1) {
					Array.prototype.push.apply(items, postids);
				}

				if (feeds == 1) {
					Array.prototype.push.apply(items, feedids);
				}

				if (chats == 1) {
					Array.prototype.push.apply(items, chatids);
				}

				if (games === 1) {
					const validGameIds = Array.isArray(gameids) ? gameids : [];
					items.push(...validGameIds);
				}

				if (items.length > 0) {
					items.sort(function (a, b) {
						return parseFloat(b.prio) - parseFloat(a.prio);
					});

					return items;
				} else {
					return null;
				}
			} else {
				return null;
			}
		},
		activeitems() {
			if (this.items && this.items.slice) {
				return this.items.slice(0, this.batch);
			} else {
				return null;
			}
		},
	},

	mounted() {
		this.batch = 10;

		this.$store.dispatch('homefetchpostids');

		this.$store.dispatch('homefetchfeedids');

		this.$store.dispatch('homefetchgames');

		this.$store.dispatch('homefetchchatids');

		window.addEventListener('scroll', this.infiniteScroll);
	},
};
</script>

<style scoped>
.prio {
	box-sizing: content-box;
	background: #fff;
	max-height: 0px;
	transition: all 0.4s;
	cursor: pointer;
	margin-bottom: 5px;
	overflow: hidden;
	padding: 5px;
	font-size: 12px;
}

.prio:hover {
	max-height: 200px;
}
</style>
