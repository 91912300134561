<template>
	<div class="view nl2br">
		<h1>{{ translate.WAITTOPLAY_KOP }}</h1>

		{{ translate.WAITTOPLAY_TEXT }}
		<br />
		<br />
		{{ translate.WAIT_BEFOREDAYS }} {{ this.current.game.daystillplay }} {{ translate.WAITTOPLAY_AFTERDAYS }}
	</div>
</template>

<script>
import {mapState} from 'vuex';
export default {
	data() {
		return {};
	},

	components: {},
	computed: {
		...mapState(['current']),
	},
	watch: {},

	methods: {},
};
</script>

<style scoped>
.view {
	text-align: center;
	padding: 30px;
}
</style>
