<template>
	<div class="header">
		<div class="left">
			<router-link :to="{name: 'Hockeydo', params: {view: 'info'}}">
				<img :src="hockeydologogrootsrc" class="logo" />
			</router-link>
		</div>

		<div class="middle">
			<div class="item" v-on:click="openmap()">
				<i class="item tekstgroen bi bi-globe"></i>
				<div class="text">{{ translate.MOBILEHEADER_CLUBZOEKER }}</div>
			</div>

			<div class="item" v-if="userhasclub" v-on:click="gotoownclub()">
				<img v-if="clubLogoUrl" class="clublogo" :src="clubLogoUrl" @error="setAltImg" />
				<!-- <div v-if="this.$store.state.current.club" class="text">{{ this.$store.state.current.club.name_short }}</div> -->
				<div class="text">{{ translate.MOBILEHEADER_MIJNCLUB }}</div>
			</div>
		</div>

		<div class="right">
			<div class="item" v-if="loggedin" v-on:click="setactiveproperty('panel', 'Notifications')">
				<i class="bi bi-bell-fill tekstgroen"></i>
				<div class="text">{{ translate.MOBILEHEADER_MELDINGEN }}</div>
			</div>

			<div class="item" v-if="loggedin" v-on:click="setactiveproperty('panel', 'Bookmarks')">
				<i class="bi bi-bookmark-fill tekstgroen"></i>
				<div class="text">{{ translate.MOBILEHEADER_OPGESLAGEN }}</div>
			</div>

			<div class="item" v-if="loggedin" v-on:click="setactiveproperty('panel', 'UserProfile')">
				<div class="profilepicturecontainer" v-html="profilepicture"></div>

				<div class="text">{{ translate.MOBILEHEADER_ACCOUNT }}</div>
			</div>

			<div class="item" v-if="!loggedin" v-on:click="setactiveproperty('panel', 'CommonWebsiteLanguage')">
				<i class="bi bi-translate tekstgroen"></i>
				<div class="text">{{ translate.MOBILEHEADER_LANGUAGE }}</div>
			</div>

			<div class="item" v-if="!loggedin">
				<div class="">
					<a v-on:click="setactiveproperty('panel', 'Login')" class="login button">{{ translate.HEADER_LOGIN }}</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Header',
	data() {
		return {};
	},

	methods: {
		openmap() {
			if (this.store.state.active.screen == 'Map') {
				this.setactiveproperty('screen', null);
			} else {
				this.setactiveproperty('screen', 'Map');
			}
		},

		gotoownclub() {
			if (typeof this.$store.state.user.club !== 'undefined') {
				var club = this.$store.state.user.club;

				this.setactiveproperty('screen', '');

				this.$router.push({name: 'Posts', params: {continent: club.continent_slug, country: club.country_slug, region: club.region_slug, club: club.slug, scope: 'club', sub: 'posts', subsub: 'all'}});
			}
		},
	},
	computed: {
		userclub() {
			return this.$store.state.user.club;
		},
		clubLogoUrl() {
			if (this.$store.state.user.club?.id) {
				return this.$store.state.config.logosurl + this.$store.state.user.club.id + '.png';
			}

			return '';
		},

		profilepicture() {
			if (this.$store.state.user?.imagename) {
				return '<img class="profilepicture" src="https://www.hockeydo.com/users/' + this.$store.state.user?.imagename + '.png"/>';
			} else {
				return (
					'<div class="profilesymbol ' +
					this.$store.state.user.firstname.charAt(0).toLowerCase() +
					'">' +
					this.$store.state.user.firstname.charAt(0).toUpperCase() +
					this.$store.state.user.lastname
						.split(' ')
						.map((word) => word.charAt(0))
						.join('')
						.toUpperCase() +
					'</div>'
				);
			}
		},

		hockeydologogrootsrc() {
			return this.$store.state.config.logosurl + 'logo-groot.png';
		},
		userhasclub() {
			if (this.$store.state.user) {
				if (this.$store.state.user.id > 0) {
					if (this.$store.state.user.clubid > 0) {
						return true;
					}
				} else {
					return false;
				}
			} else {
				return false;
			}

			return false;
		},
	},
};
</script>

<style scoped>
.header {
	position: relative;
	height: 60px;
}

.header .left {
	position: absolute;
	top: 0;
	left: 0;
	height: 65px;
	width: 280px;
}

.header .middle {
	margin: 0 auto;
	width: 100%;
	position: relative;
	height: 65px;
	display: flex;
	justify-content: content-start;
}

.header .right {
	position: absolute;
	right: 0px;
	top: 0;
	bottom: 0;
	width: 280px;
	height: 65px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.header .logo {
	position: absolute;
	top: 15px;
	left: 25px;
	width: 12vw;
	height: auto;
}

.header .user {
	padding: 0;
	overflow: hidden;
	float: right;
}
.header .user .icon {
	float: right;
	padding: 10px 5px;
}

.header .item {
	text-align: center;
	margin: 5px 10px;
	height: 65px;
	position: relative;
}

.header .item .text {
	font-size: 11px;
	width: 100%;
	text-align: center;
	color: #3bb58e;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 3px;
	white-space: nowrap;
}

.header .profilepicturecontainer {
	padding: 5px;
}

.header .clublogo {
	margin: 5px;
	width: 35px;
	height: 35px;
}

.header i {
	padding: 5px;
}

.header .bi-bell-fill {
	font-size: 30px;
}

.header .bi-bookmark-fill {
	font-size: 30px;
}

.header .bi-translate {
	font-size: 34px;
}

.header .login {
	margin-top: 12px;
}

.header .clubzoeker {
}

/* SM */
@media (min-width: 576px) {
}

/* MD */
@media (min-width: 768px) {
	.header .middle {
		max-width: 1000px;
		margin: 0 auto;
	}
}

/* LG */
@media (min-width: 992px) {
	.header .middle {
		margin: 0 auto;
		width: calc(100vw - 320px);
		max-width: 800px;
	}
}

/* XL */
@media (min-width: 1200px) {
}
</style>
