<template>
	<div class="mobileheader">
		<div class="item">
			<router-link class="item" :to="{name: 'Hockeydo', params: {view: 'info'}}">
				<img :src="hockeydologokleinsrc" class="logo" />
				<div class="text">{{ translate.MOBILEHEADER_INFO }}</div>
			</router-link>
		</div>

		<div class="item">
			<i v-on:click="openmap()" class="item tekstgroen bi bi-globe"></i>
			<div class="text">{{ translate.MOBILEHEADER_CLUBZOEKER }}</div>
		</div>

		<div class="item" v-if="userhasclub" v-on:click="gotoownclub()">
			<img v-if="clubLogoUrl" class="clublogo" :src="clubLogoUrl" @error="setAltImg" />
			<!-- <div v-if="this.$store.state.current.club" class="text">{{ this.$store.state.current.club.name_short }}</div> -->
			<div class="text">{{ translate.MOBILEHEADER_MIJNCLUB }}</div>
		</div>

		<div class="item" v-if="loggedin" v-on:click="setactiveproperty('panel', 'Notifications')">
			<i class="bi bi-bell-fill tekstgroen"></i>
			<div class="text">{{ translate.MOBILEHEADER_MELDINGEN }}</div>
		</div>

		<div class="item" v-if="loggedin" v-on:click="setactiveproperty('panel', 'Bookmarks')">
			<i class="bi bi-bookmark-fill tekstgroen"></i>
			<div class="text">{{ translate.MOBILEHEADER_OPGESLAGEN }}</div>
		</div>

		<div class="item" v-if="loggedin" v-on:click="setactiveproperty('panel', 'UserProfile')">
			<div class="profilepicture" v-html="profilepicture"></div>
			<div class="text">{{ translate.MOBILEHEADER_ACCOUNT }}</div>
		</div>

		<div class="item" v-if="!loggedin" v-on:click="setactiveproperty('panel', 'CommonWebsiteLanguage')">
			<i class="item bi bi-translate tekstgroen"></i>
			<div class="text">{{ translate.MOBILEHEADER_LANGUAGE }}</div>
		</div>

		<div class="item" v-if="!loggedin">
			<div class="item">
				<a v-on:click="setactiveproperty('panel', 'Login')" class="login button">{{ translate.HEADER_LOGIN }}</a>
			</div>
		</div>
	</div>
</template>

<script>
// import axios from 'axios';

export default {
	name: 'Header',
	data() {
		return {
			latestcompetition: null,
		};
	},

	methods: {
		openmap() {
			if (this.store.state.active.screen == 'Map') {
				this.setactiveproperty('screen', null);
			} else {
				this.setactiveproperty('screen', 'Map');
			}
		},

		closesearch() {
			this.searchstring = '';
			this.setactiveproperty('searchstring', '');
		},

		gotoownclub() {
			if (typeof this.$store.state.user.club !== 'undefined') {
				var club = this.$store.state.user.club;

				this.$router.push({name: 'Posts', params: {continent: club.continent_slug, country: club.country_slug, region: club.region_slug, club: club.slug, scope: 'club', sub: 'posts', subsub: 'all'}});
			}
		},
	},
	computed: {
		userclub() {
			return this.$store.state.user.club;
		},
		profilepicture() {
			if (this.$store.state.user?.imagename) {
				return '<img class="profilepicture" src="https://www.hockeydo.com/users/' + this.$store.state.user?.imagename + '.png"/>';
			} else {
				return (
					'<div class="profilesymbol mobileheader ' +
					this.$store.state.user.firstname.charAt(0).toLowerCase() +
					'">' +
					this.$store.state.user.firstname.charAt(0).toUpperCase() +
					this.$store.state.user.lastname
						.split(' ')
						.map((word) => word.charAt(0))
						.join('')
						.toUpperCase() +
					'</div>'
				);
			}
		},
		hockeydologokleinsrc() {
			return this.$store.state.config.logosurl + 'logo-klein.png';
		},

		searchstring: {
			get() {
				return this.$store.state.active.searchstring;
			},
			set(value) {
				var obj = new Object();
				obj.property = 'searchstring';
				obj.value = value;
				this.$store.commit('setactiveproperty', obj);
			},
		},
		clubLogoUrl() {
			if (this.$store.state.user.club?.id) {
				return this.$store.state.config.logosurl + this.$store.state.user.club.id + '.png';
			}

			return '';
		},
		userhasclub() {
			if (this.$store.state.user) {
				if (this.$store.state.user.id > 0) {
					if (this.$store.state.user.clubid > 0) {
						return true;
					}
				} else {
					return false;
				}
			} else {
				return false;
			}

			return false;
		},
	},
};
</script>

<style scoped>
.mobileheader {
	display: flex;
	justify-content: space-between;
	margin-right: 10px;
	margin-left: 10px;
}

.logo {
	height: 35px;
	margin-top: 5px;
	margin-bottom: -3px;
	width: auto;
}

.item {
	text-align: center;
}

i {
	padding: 10px 5px 0px 5px;
	margin: 0 auto;
	font-size: 30px;
}

.profilepicture {
	margin-top: 5px;
	margin-right: 0;
	margin-bottom: -3px;
}

.b {
	border: 1px solid #000;
}

.item .text {
	font-size: 11px;
	width: 100%;
	text-align: center;
	color: #3bb58e;
}

.clublogo {
	height: 45px;
	width: auto;
	padding: 8px 5px 0px 5px;
	margin-bottom: -8px;
}

.login {
	margin-top: 10px;
}
</style>
