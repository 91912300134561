<template>
	<a class="panel_section" v-if="post" v-on:click="showpost()">
		<div class="row mb10">
			<div class="links">
				<div v-html="profilepicture"></div>
			</div>

			<div class="midden heelklein tekstgrijs verticalcenter">
				<div class="dik tekstzwart">{{ fullname }}</div>
				<div>{{ time }} &bull; {{ post.clubNamelong }} &bull; {{ post.clubPlace }} &bull; {{ clubCountry }} &bull; {{ post.language }} &bull; {{ post.id }}</div>
			</div>

			<div class="rechts"></div>
		</div>

		<div class="row">
			<div class="links"></div>

			<div class="midden text">
				{{ post.text }}
			</div>
		</div>
	</a>
</template>

<script>
import axios from 'axios';

export default {
	data() {
		return {
			post: null,
		};
	},

	props: ['postid'],
	methods: {
		showpost() {
			this.$router.push({path: this.$route.path, query: {postid: this.postid}});
			this.$store.dispatch('taskShowPanel', null);
		},

		getpost() {
			if (this.$store.state.config.debug == 1) {
				console.log('Bookmarks: getpost: ' + this.postid);
			}

			var post = new Object();
			post.task = 'getpost';
			post.postid = this.postid;

			// console.log(post);

			axios.post(this.$store.state.config.apiserver + 'users/panels/BookmarksPosts.php', post)
				.then((response) => {
					this.post = response.data;
					// console.log(response.data);
				})
				.catch((error) => {
					this.errorMessage = error.message;
					console.error('There was an error!', error);
				});
		},

		calctime() {
			var now = (Date.now() / 1000) | 0;
			var created = this.post.unixtime;
			var diff = now - created;

			var minutes = Math.floor(diff / 60);
			if (minutes < 61) {
				return minutes + ' ' + this.$store.state.translate.POSTPOST_MINUTEN;
			}

			var hours = Math.floor(minutes / 60);
			if (hours < 25) {
				if (hours == 1) {
					return hours + ' ' + this.$store.state.translate.POSTPOST_UUR;
				} else {
					return hours + ' ' + this.$store.state.translate.POSTPOST_UREN;
				}
			}

			var days = Math.floor(hours / 24);
			if (days < 31) {
				if (days == 1) {
					return days + ' ' + this.$store.state.translate.POSTPOST_DAG;
				} else {
					return days + ' ' + this.$store.state.translate.POSTPOST_DAGEN;
				}
			}

			var months = Math.floor(days / 30);
			if (months < 12) {
				if (months == 1) {
					return months + ' ' + this.$store.state.translate.POSTPOST_MAAND;
				} else {
					return months + ' ' + this.$store.state.translate.POSTPOST_MAANDEN;
				}
			}

			var years = Math.floor(months / 12);

			if (years == 1) {
				return years + ' ' + this.$store.state.translate.POSTPOST_JAAR;
			} else {
				return years + ' ' + this.$store.state.translate.POSTPOST_JAREN;
			}
		},
	},

	computed: {
		profilepicture() {
			if (this.post.userimagename) {
				return '<img class="profilepicture" src="https://www.hockeydo.com/users/' + this.post.userimagename + '.png"/>';
			} else {
				return (
					'<div class="profilesymbol ' +
					this.post.userFirstname.charAt(0).toLowerCase() +
					'">' +
					this.post.userFirstname.charAt(0).toUpperCase() +
					this.post.userLastname
						.split(' ')
						.map((word) => word.charAt(0))
						.join('')
						.toUpperCase() +
					'</div>'
				);
			}
		},

		fullname() {
			return this.post.userFirstname + ' ' + this.post.userLastname;
		},

		clubCountry() {
			return this.post['clubCountry' + this.$store.state.active.languagewebsite];
		},

		time() {
			return this.calctime();
		},

		postidchanges() {
			return this.postid;
		},
	},

	watch: {
		postidchanges() {
			console.log(this.postid);
		},
	},

	mounted() {
		this.getpost();
	},
};
</script>
