<template>
	<transition name="slideup">
		<div v-if="this.$store.state.active.panel == panelname" class="panel">
			<div v-if="this.$store.state.config.debug == 1" class="debug">Panel | {{ panelname }}</div>

			<div class="row panel_header">
				<div class="col col-6 middle">
					<h1>{{ translate.LOGIN_KOP }}</h1>
				</div>
				<div class="col-6 middle-right">
					<a v-on:click="setactiveproperty('panel', '')" class="bi bi-x tekstblauw floatright"></a>
				</div>
			</div>

			<div class="panel_content horizontalverticalcenter">
				<div>
					<h2>{{ translate.LOGIN_TEKST }}</h2>
					<br />

					<form @submit="postdata">
						<div class="row">
							<div class="col col-12">
								<input type="text" class="text" v-model="email" :placeholder="[[translate.CREATEACCOUNT_EMAIL]]" />
							</div>
						</div>

						<div class="row">
							<div class="col col-12">
								<input type="password" class="text" v-model="password" :placeholder="[[translate.CREATEACCOUNT_PASSWORD]]" />
							</div>
						</div>

						<div v-if="message" class="row">
							<div class="col col-12">
								<div class="message tekstrood">{{ message }}</div>
							</div>
						</div>

						<div class="row">
							<div class="col col-12">
								<div v-if="postok">
									<button class="button" type="submit">{{ translate.LOGIN_BUTTON }}</button>
								</div>
								<div v-else>
									<a class="button grijs">{{ translate.LOGIN_BUTTON }}</a>
								</div>
							</div>
						</div>
					</form>

					<div class="row">
						<div class="col col-6">
							<a v-on:click="setactiveproperty('toppanel', 'LostPassword')" class="">{{ translate.LOGIN_LOSTPASTWORD }}</a>
						</div>
						<div class="col col-6">
							<a v-on:click="setactiveproperty('toppanel', 'CreateAccount')" class="alignright mr15">{{ translate.LOGIN_CREATEACCOUNT }}</a>
						</div>
					</div>
					<div class="of">{{ translate.LOGIN_SOCIALS }}</div>
					<br />
					<a :href="loginurl + '&provider=facebook'" class="button">
						<i class="bi bi-facebook"></i>
						{{ translate.LOGIN_LOGINWITHFACEBOOK }}
					</a>

					<a :href="loginurl + '&provider=google'" class="button">
						<i class="bi bi-google"></i>
						{{ translate.LOGIN_LOGINWITHGOOGLE }}
					</a>

					<a :href="loginurl + '&provider=twitter'" class="button">
						<i class="bi bi-x"></i>
						{{ translate.LOGIN_LOGINWITHTWITTER }}
					</a>

					<a :href="loginurl + '&provider=linkedin'" class="button">
						<i class="bi bi-x"></i>
						Linkedin
					</a>

					<br />
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import axios from 'axios';

export default {
	name: 'Login',
	data() {
		return {
			panelname: 'Login',
			email: '',
			password: '',
			message: '',
		};
	},
	computed: {
		translate() {
			return this.$store.state.translate;
		},
		loginurl() {
			return 'https://www.hockeydo.com/auth/login.php?return=' + encodeURIComponent(window.location);
		},

		postok() {
			if (this.password.trim() && this.email.trim()) {
				return true;
			} else {
				return false;
			}
		},
		panelopen() {
			if (this.$store.state.active.toppanel == this.panelname) {
				return true;
			} else {
				return false;
			}
		},
	},
	methods: {
		postdata(e) {
			e.preventDefault();

			this.message = '';

			if (this.$store.state.config.debug == 1) {
				console.log('loginwithemail');
			}

			var post = new Object();
			post.task = 'loginwithemail';
			post.email = this.email;
			post.password = this.password;
			post.returnurl = window.location.origin;

			// console.log(post);

			axios.post(this.$store.state.config.apiserver + 'users/panels/Login.php', post)
				.then((response) => {
					if (response.data.state == 'passwordNotOk') {
						this.message = this.$store.state.translate.LOGIN_WRONGPASSWORD;
					}

					if (response.data.state == 'passwordOk') {
						var url = response.data.returnurl + '?hdaccesstoken=' + response.data.hdaccesstoken;

						console.log(url);

						window.location.href = url;
					}
				})
				.catch((error) => {
					this.errorMessage = error.message;
					console.error('There was an error!', error);
				});
		},
	},
};
</script>

<style scoped>
input {
	width: 400px;
}

.button,
button {
	width: 400px;
	margin-bottom: 15px;
}

.of {
	width: 100%;
	border-top: 1px solid #eee;
	margin-top: 15px;
	padding-top: 15px;
	text-align: center;
}

.message {
	padding: 0px 15px 15px 0px;
}
</style>
