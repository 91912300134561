<template>
	<transition name="slideup">
		<div v-if="this.$store.state.active.toppanel == panelname" class="panel">
			<div v-if="this.$store.state.config.debug == 1" class="debug">Toppanel | {{ panelname }}</div>

			<div class="row panel_header middle">
				<div class="col col-6 middle">
					<h1>{{ translate.FILTER_KIESLAND }}</h1>
				</div>
				<div class="col-6 middle-right">
					<a v-on:click="setactiveproperty('toppanel', '')" class="bi bi-x tekstblauw floatright"></a>
				</div>
			</div>

			<div class="panel_content">
				<div v-for="country in countries" :key="country.id">
					<a class="ghost button mb10" v-on:click="setcountry(country)">{{ getcountry(country) }}</a>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import {mapState} from 'vuex';
export default {
	data() {
		return {
			panelname: 'FilterAreaAreaCountries',
		};
	},

	components: {},
	methods: {
		getcountry(country) {
			return country[this.$store.state.active.languagewebsite];
		},

		setcountry(country) {
			const clubsInCountry = this.staticdata.clubs.filter((club) => club.country_id === country.id);

			this.setmapsproperty('clubs', clubsInCountry);

			this.setmapsproperty('country', country);
			this.setmapsproperty('region', null);
			this.setmapsproperty('club', null);

			this.setactiveproperty('toppanel', '');
		},
	},

	computed: {
		...mapState(['staticdata', 'active', 'maps']),
		countries() {
			const continentId = this.maps.continent.id;
			return this.staticdata.ContinentsCountriesRegions.filter((item) => item.continent.id === continentId)
				.map((item) => item.country)
				.filter((country, index, self) => index === self.findIndex((t) => t.id === country.id))
				.sort((a, b) => {
					// Sort based on the English names
					const nameA = a.en.toLowerCase();
					const nameB = b.en.toLowerCase();

					if (nameA < nameB) return -1;
					if (nameA > nameB) return 1;
					return 0;
				});
		},
	},
};
</script>

<style scoped></style>
