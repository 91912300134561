<template>
	<div class="">
		<div class="postsfilter">
			<div class="postsfilter_scroll middle">
				<span v-if="currentlanguagesid">
					<a v-on:click="setactiveproperty('toppanel', 'PostsFilterLanguages')" class="button ghost arrow-down mr15">{{ currentlanguages }}</a>
				</span>
				<span v-else>
					<a v-on:click="setactiveproperty('toppanel', 'PostsFilterLanguages')" class="button funnel arrow-down mr15">{{ currentlanguages }}</a>
				</span>

				<span v-if="this.$store.state.active.filterlocation.id == 1">
					<a v-on:click="setactiveproperty('toppanel', 'PostsFilterLocations')" class="button ghost arrow-down mr15">{{ this.$store.state.active.filterlocation[this.$store.state.active.languagewebsite] }}</a>
				</span>
				<span v-else>
					<a v-on:click="setactiveproperty('toppanel', 'PostsFilterLocations')" class="button funnel arrow-down mr15">{{ this.$store.state.active.filterlocation[this.$store.state.active.languagewebsite] }}</a>
				</span>

				<span v-if="this.$store.state.active.filtercat == null">
					<a v-on:click="setactiveproperty('toppanel', 'PostsFilterCats')" class="button ghost arrow-down mr15">{{ translate.POSTCATFILTER_ALLES }}</a>
				</span>
				<span v-else>
					<a v-on:click="setactiveproperty('toppanel', 'PostsFilterCats')" class="button funnel arrow-down mr15">{{ this.$store.state.active.filtercat[this.$store.state.active.languagewebsite] }}</a>
				</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'PostsHeader',
	data() {
		return {};
	},
	methods: {},

	computed: {
		currentlanguages() {
			if (this.$store.state.active.filterlanguagesids) {
				var languagereadids = this.$store.state.active.filterlanguagesids.split(',');

				if (languagereadids.includes('1')) {
					return this.translate.LANGUAGES_ALLETALEN;
				} else {
					var currentlanguages = this.$store.state.active.filterlanguagesids.split(',');

					var lang = '';

					this.$store.state.staticdata.languages.forEach(function (language) {
						if (currentlanguages.includes(language.id)) {
							lang += language.abbreviation + ' | ';
						}
					});

					if (lang && lang.slice) {
						lang = lang.slice(0, -2);
					}

					return lang;
				}
			} else {
				return '';
			}
		},

		currentlanguagesid() {
			var languagereadids = this.$store.state.active.filterlanguagesids.split(',');

			if (languagereadids.includes('1')) {
				return true;
			} else {
				return false;
			}
		},
		currenlocationID() {
			return this.$store.state.active.location;
		},

		currentlocation() {
			var currentlocation = this.$store.state.active.filterlocationid;

			var locationname = '';

			var language = this.$store.state.active.languagewebsite;

			this.$store.state.staticdata.locations.forEach(function (location) {
				if (location.id == currentlocation) {
					locationname = location[language];
				}
			});

			return locationname;
		},

		userclub() {
			return this.$store.state.user.club;
		},

		usercanwrite() {
			if (this.loggedin) {
				// Admins mogen overal posten
				if (this.$store.state.user.userrole == '2') {
					return true;
				}

				if (this.$route.name == 'We') {
					return true;
				}

				if (this.$route.name == 'Games') {
					return true;
				}

				if (this.$route.name == 'Posts') {
					if (this.$store.state.user.club) {
						if (this.$route.params.scope == 'club') {
							if (this.$store.state.user.club.slug == this.$route.params.club) {
								return true;
							} else {
								return false;
							}
						}

						if (this.$route.params.scope == 'region') {
							if (this.$store.state.user.club.region_slug == this.$route.params.region) {
								return true;
							} else {
								return false;
							}
						}

						if (this.$route.params.scope == 'country') {
							if (this.$store.state.user.club.country_slug == this.$route.params.country) {
								return true;
							} else {
								return false;
							}
						}

						if (this.$route.params.scope == 'continent') {
							if (this.$store.state.user.club.continent_slug == this.$route.params.continent) {
								return true;
							} else {
								return false;
							}
						}
					}
				}

				if (this.$route.name == 'We') {
					console.log('bob');
				}
			} else {
				return false;
			}

			return false;
		},
	},
};
</script>

<style scoped>
.postsfilter {
	background-color: #fff;
	overflow: hidden;
	white-space: nowrap;
	padding: 5px 10px;
	margin-bottom: 5px;
	border-radius: 5px;
	margin-left: 0px;
	margin-right: 0px;
}

.postsfilter_scroll {
	display: flex; /* Maakt de knoppen naast elkaar in een rij */
	overflow-x: auto; /* Maakt horizontaal scrollen mogelijk */
	-webkit-overflow-scrolling: touch; /* Soepeler scrollen op iOS apparaten */
	scroll-snap-type: x mandatory; /* Zorgt voor snapping gedrag bij scrollen */
	white-space: nowrap; /* Zorgt dat items niet afbreken naar de volgende regel */
}

.postsfilter_scroll .button {
	scroll-snap-align: start; /* Zorgt ervoor dat elke knop netjes uitlijnt bij het scrollen */
	flex-shrink: 0; /* Voorkomt dat knoppen in grootte veranderen */
	margin-right: 15px; /* Ruimte tussen de knoppen */
}

.postsfilter_scroll::-webkit-scrollbar {
	display: none;
}

/* SM */
@media (min-width: 576px) {
}

/* MD */
@media (min-width: 768px) {
}

/* LG */
@media (min-width: 992px) {
}

/* XL */
@media (min-width: 1200px) {
}
</style>
