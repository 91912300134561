<template>
	<transition name="slideup">
		<div v-if="panelopen" class="panel">
			<div v-if="this.$store.state.config.debug == 1" class="debug">Panel | {{ panelname }}</div>

			<div class="row panel_header">
				<div class="col col-6 middle">
					<h1>{{ translate.CREATEACCOUNT_KOP }}</h1>
				</div>
				<div class="col-6 middle-right">
					<a v-on:click="setactiveproperty('toppanel', '')" class="bi bi-x tekstblauw floatright"></a>
				</div>
			</div>

			<div v-if="state == 'createaccount'" class="panel_content">
				<form @submit="postdata">
					<div class="row mb15">
						<div class="col col-12">
							{{ translate.CREATEACCOUNT_TEXT }}
						</div>
					</div>
					<div class="row">
						<div class="col col-6">
							<input type="text" class="text" v-model="firstname" :placeholder="[[translate.CREATEACCOUNT_FIRSTNAME]]" />
						</div>

						<div class="col col-6">
							<input type="text" class="text" v-model="lastname" :placeholder="[[translate.CREATEACCOUNT_LASTNAME]]" />
						</div>
					</div>

					<div class="row">
						<div class="col col-12">
							<input type="text" class="text" v-model="email" :placeholder="[[translate.CREATEACCOUNT_EMAIL]]" />
						</div>
					</div>

					<div class="row">
						<div class="col col-12">
							<input type="password" class="text" v-model="password" :placeholder="[[translate.CREATEACCOUNT_PASSWORD]]" />
						</div>
					</div>

					<div class="row">
						<div class="col col-12 alignright">
							<div v-if="postok">
								<button class="button floatright" type="submit">{{ translate.CREATEACCOUNT_BUTTON }}</button>
							</div>
							<div v-else>
								<a class="button floatright grijs">{{ translate.CREATEACCOUNT_BUTTON }}</a>
							</div>
						</div>
					</div>
				</form>
			</div>

			<div v-if="state == 'emailexists'" class="panel_content horizontalverticalcenter">
				<h1>{{ translate.CREATEACCOUNT_EMAILEXISTS }}</h1>
			</div>

			<div v-if="state == 'accountcreated'" class="panel_content horizontalverticalcenter">
				<h1>{{ translate.CREATEACCOUNT_ACCOUNTCREATED }}</h1>
			</div>
		</div>
	</transition>
</template>

<script>
import axios from 'axios';

export default {
	name: 'CreateAccount',
	data() {
		return {
			panelname: 'CreateAccount',
			firstname: '',
			lastname: '',
			password: '',
			email: '',
			state: 'createaccount',
		};
	},
	computed: {
		translate() {
			return this.$store.state.translate;
		},
		postok() {
			if (this.password.trim() && this.firstname.trim() && this.lastname.trim() && this.email.trim() && this.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
				return true;
			} else {
				return false;
			}
		},
		panelopen() {
			if (this.$store.state.active.toppanel == this.panelname) {
				return true;
			} else {
				return false;
			}
		},
	},
	methods: {
		postdata(e) {
			e.preventDefault();

			if (this.$store.state.config.debug == 1) {
				console.log('createaccount');
			}

			var post = new Object();
			post.task = 'createaccount';
			post.firstname = this.firstname;
			post.lastname = this.lastname;
			post.email = this.email;
			post.password = this.password;
			post.subject = this.$store.state.translate.CREATEACCOUNT_EMAIL_SUBJECT;
			post.body = this.$store.state.translate.CREATEACCOUNT_EMAIL_BODY;
			post.footer = this.$store.state.translate.CREATEACCOUNT_EMAIL_FOOTER;
			post.linktext = this.$store.state.translate.CREATEACCOUNT_EMAIL_LINKTEXT;

			// console.log(post);

			axios.post(this.$store.state.config.apiserver + 'users/panels/CreateAccount.php', post)
				.then((response) => {
					console.log(response.data);

					this.state = response.data.state;
				})
				.catch((error) => {
					this.errorMessage = error.message;
					console.error('There was an error!', error);
				});
		},
	},
	watch: {
		panelopen(newValue) {
			if (newValue === true) {
				// Reset state en form data wanneer panel opent
				this.state = 'createaccount';
				this.firstname = '';
				this.lastname = '';
				this.email = '';
				this.password = '';
			}
		},
	},
};
</script>
