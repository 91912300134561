<template>
	<div class="menu" v-if="current.competition">
		<div class="section">
			<router-link :to="{name: 'Games', params: {competition: current.competition.slug, game: 'game-' + current.game.game, video: 'video-' + current.video.nummer, mode: 'game'}}">
				<span v-if="current.mode == 'game'">
					<a class="button mr10">{{ translate.GAMESHEADER_GAME }}</a>
				</span>

				<span v-else>
					<a class="button ghost mr10">{{ translate.GAMESHEADER_GAME }}</a>
				</span>
			</router-link>

			<router-link v-if="loggedin" :to="{name: 'Games', params: {competition: current.competition.slug, game: 'game-' + current.game.game, video: 'video-' + current.video.nummer, mode: 'ranking', scope: 'club'}}">
				<span v-if="current.mode == 'ranking'">
					<a class="button mr10">{{ translate.GAMESHEADER_RANKINGS }}</a>
				</span>

				<span v-else>
					<a class="button ghost mr10">{{ translate.GAMESHEADER_RANKINGS }}</a>
				</span>
			</router-link>
		</div>

		<div class="section">
			<a class="button arrow-down mr10" v-on:click="setactiveproperty('panel', 'GamesMenu')">{{ current.competition['title_' + languagewebsite] }}</a>

			<a class="button arrow-down mr10" v-on:click="setactiveproperty('panel', 'GamesMenu')">{{ translate.GAMESHEADER_WEEK }} {{ current.game.game }}</a>

			<a class="button arrow-down mr10" v-if="current.video" v-on:click="setactiveproperty('panel', 'GamesMenu')">{{ translate.GAMESHEADER_VIDEO }} {{ current.video.nummer }}</a>
		</div>
	</div>
</template>

<script>
// import axios from "axios";
import {mapState} from 'vuex';

export default {
	data() {
		return {};
	},

	methods: {},

	computed: {
		...mapState(['current', 'competitions']),
	},
	watch: {},
	mounted() {},
};
</script>

<style scoped>
.section {
	display: flex;
	flex-direction: row;
	background: #fff;
	padding: 5px;
	margin-bottom: 5px;
	border-radius: 10px;
}
</style>
