<template>
	<transition name="slideup">
		<div v-if="this.store.state.active.toppanel == panelname" class="panel">
			<div v-if="this.store.state.config.debug == 1" class="debug">Toppanel | {{ panelname }}</div>

			<div class="row panel_header middle">
				<div class="col col-6 middle">
					<h1>{{ translate.POSTCREATE_VELDOFZAAL }}</h1>
				</div>
				<div class="col-6 middle-right">
					<a v-on:click="setactiveproperty('toppanel', '')" class="bi bi-x tekstblauw floatright"></a>
				</div>
			</div>

			<div class="panel_content">
				<div v-for="location in locations" :key="location.id">
					<a class="button ghost mb10" v-on:click="setlocation(location)">{{ location[[this.$store.state.active.languagewebsite]] }}</a>
				</div>
			</div>

			div
		</div>
	</transition>
</template>

<script>
export default {
	data() {
		return {
			panelname: 'PostCreateLocation',
		};
	},

	methods: {
		setlocation(location) {
			this.setactiveproperty('postlocation', location);
			this.setactiveproperty('toppanel', '');
		},
	},

	computed: {
		locations() {
			return this.store.state.staticdata.locations;
		},
	},
};
</script>

<style scoped></style>
